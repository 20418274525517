import React, {useState, useEffect} from 'react'
import {getAddDistributor} from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Appbar from '../../Appbar/Appbar'
import Sidebar from '../Sidebar/Sidebar'
import "../DistributorData.css"
import { useParams } from 'react-router-dom'
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
export default function DGroup() {
  const{id} = useParams();
  useEffect(() => {
    fetchDistributor();
  }, []);
  const [addDistributor,setAddDistributor]=useState({})
  const fetchDistributor = async () => {
    try {
    const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
    const DistribitorList = DistributorData.data.getAddDistributor;
    console.log(DistribitorList);
    setAddDistributor(DistribitorList);
    } catch (error) {
        console.log(error)
    }
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  return (
    <div>
<Appbar item={id} name={addDistributor.firstname}/>
      <Sidebar item={id}/>
      <div className="SalesContainer">
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '100%',
          height: '100%',
        },
      }}>
        <h3>My Upline Sponsor</h3>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 50 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Distributor Name</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Relation</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Contact No</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="left">Email</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Last Login</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
             <StyledTableRow>
             <StyledTableCell align="left"> </StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             </StyledTableRow>
           

        </TableBody>
      </Table>
      </TableContainer>
          
         
          <h3>Down Line network</h3>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 50 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Distributor Name</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Relation</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Contact No</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="left">Email</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Last Login</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
             <StyledTableRow>
             <StyledTableCell align="left"> </StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             <StyledTableCell align="left"></StyledTableCell>
             </StyledTableRow>
           

        </TableBody>
      </Table>
      </TableContainer>
      </Box>
      </div>
    </div>
  )
}

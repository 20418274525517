import React, {useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './AddProduct.css'
import Stack from '@mui/material/Stack';
import {Button} from '@mui/material';
import { getAddproduct} from '../../../../graphql/queries';
import { API, graphqlOperation,Storage } from 'aws-amplify';
import {updateAddproduct} from '../../../../graphql/mutations';
import {v4 as uuid} from 'uuid';
export default function ProductItem() {
    const {id} = useParams();
    const [imageData,setImageData]=useState()
    const [state, setState]=useState({
        profileImg:'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
    })
    const {profileImg} = state
  const  fileSelectedHandler= (event) =>{
        console.log(event.target.files[0])
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2)
            {
            setState({profileImg: reader.result})
        }
    }
    reader.readAsDataURL(event.target.files[0])
};
const [product, setProducts] = useState([]);
useEffect(() => {
    fetchProduct();
  }, []);
  const fetchProduct = async () => {
      try {
          const productData = await API.graphql(graphqlOperation(getAddproduct, { id: id }));
          const productList = productData.data.getAddproduct;
          console.log(productList);
          setProducts(productList);
      }
      catch (error){
        console.log('error on fetching product', error);
      }
    };
    const [updateProduct,setUpdateProduct]=useState({})
    const handleClick= async()=>{
        const {key} = await Storage.put(`${uuid()}.jpg`,imageData, {contentType:'image/jpg'});
        const productFilePath = key;
        const fileAccessURL = await Storage.get(productFilePath)
        console.log('access url', fileAccessURL);
        const {productCode,productName,diamondShape,shapeCutting,diamondColor,diamondCarat,diamondClarity,measurement,ratio,certificateNo,msrp,currency}=updateProduct;
        const createUpdateProductInput = {
            id: id,
            productCode,
            productName,
            diamondShape,
            shapeCutting,
            diamondColor,
            diamondCarat,
            diamondClarity,
            measurement,
            ratio,
            certificateNo,
            msrp,
            currency,
            image:fileAccessURL
            }
            await API.graphql(graphqlOperation(updateAddproduct, {input: createUpdateProductInput}));
        alert('Product Updated Successfully');
    };
  return (
    <div>
         <Appbar/>
        <Sidebar/>
        <div className="DistributorContainer">
        <h2>Edit Products</h2>
        <img className="ProductImg-Name" src={product.image} id="img"/>
        <br/>
        <Stack direction="row" alignItems="center" spacing={2} mb={2}>
              <Button variant="contained" component="label" onChange={fileSelectedHandler} style={{ background: 'rgb(64,64,64)' }}>
                Upload
                <input hidden accept="image/png, image/jpeg" multiple type="file"  onChange={e=> setImageData(e.target.files[0])}/>
             </Button>
              </Stack>
              <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Product Code</label>
            <input type="text" placeholder="Product Code" defaultValue={product.productCode}
            onChange={e =>setUpdateProduct({...updateProduct, productCode:e.target.value})}
            />
        </div>
        <div className="newDeliveryItem1">
            <label>Product Name</label>
            <input type="text" placeholder="Product Name" defaultValue={product.productName}
            onChange={e =>setUpdateProduct({...updateProduct, productName:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Shape</label>
            <input type="text" placeholder="Diamond Shape" defaultValue={product.diamondShape}
            onChange={e =>setUpdateProduct({...updateProduct, diamondShape:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Cutting</label>
            <input type="text" placeholder="Cutting" defaultValue={product.shapeCutting}
            onChange={e =>setUpdateProduct({...updateProduct, shapeCutting:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Color</label>
            <input type="text" placeholder="Diamond Color" defaultValue={product.diamondColor}
            onChange={e =>setUpdateProduct({...updateProduct, diamondColor:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Carat</label>
            <input type="text" placeholder="Diamond Carat" defaultValue={product.diamondCarat}
            onChange={e =>setUpdateProduct({...updateProduct, diamondCarat:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Clarity</label>
            <input type="text" placeholder="Diamond Clarity" defaultValue={product.diamondClarity}
            onChange={e =>setUpdateProduct({...updateProduct, diamondClarity:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Measurements</label>
            <input type="text" placeholder="Measurement" defaultValue={product.measurement}
            onChange={e =>setUpdateProduct({...updateProduct, measurement:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Length/Width Ratio</label>
            <input type="text" placeholder="Ratio" defaultValue={product.ratio}
            onChange={e =>setUpdateProduct({...updateProduct, ratio:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Certificate No</label>
            <input type="text" placeholder="Certificate No" defaultValue={product.certificateNo}
            onChange={e =>setUpdateProduct({...updateProduct, certificateNo:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>MSRP</label>
            <input type="text" placeholder="MSRP" defaultValue={product.msrp}
            onChange={e =>setUpdateProduct({...updateProduct, msrp:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Currency</label>
            <input type="text" placeholder="Currency" defaultValue={product.currency}
            onChange={e =>setUpdateProduct({...updateProduct, currency:e.target.value})}/>
        </div>
      </div>
      <Stack direction="row" alignItems="center" spacing={2} mb={2} mt={2}>
              <Button variant="contained" component="label" style={{ background: 'rgb(64,64,64)' }} onClick={handleClick}>
                Update
             </Button>
              </Stack>
    </div>
    </div>
  )
}

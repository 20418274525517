import React, {useState} from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './AddProduct.css'
import TextField from '@mui/material/TextField';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {createAddproduct} from '../../../../graphql/mutations';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import {v4 as uuid} from 'uuid';
export default function AddProduct () {
    const [addProduct,setAddProduct]=useState({})
    const [imageData,setImageData]=useState()
    const handleClick= async()=>{
      const {key} = await Storage.put(`${uuid()}.jpg`,imageData, {contentType:'image/jpg'});
      console.log('Add Product',addProduct)
      try {
        const productFilePath = key;
        const fileAccessURL = await Storage.get(productFilePath, {expires: 60 * 60 * 24 * 7})
        console.log('access url', fileAccessURL);
        const {productCode,productName,diamondShape,shapeCutting,diamondColor,diamondCarat,diamondClarity,certificateNo,msrp,currency}=addProduct;
        const createAddProductInput = {
        id: uuid(),
        productCode,
        productName,
        diamondShape,
        shapeCutting,
        diamondColor,
        diamondCarat,
        diamondClarity,
        certificateNo,
        msrp,
        currency,
        image: fileAccessURL,
        }
    await API.graphql(graphqlOperation(createAddproduct, {input: createAddProductInput}));
    alert('Product Added Successfully');
    setAddProduct({
      productCode:'',
      productName:'',
       diamondShape:'None',
          shapeCutting:'None',
          diamondColor:'None',
          diamondCarat:'',
          diamondClarity:'None',
          certificateNo:'',
          msrp:'',
          currency:'None',
  })
      }catch(error){
        console.log('Add Product error', error);
        alert('You must add images');
      }
    };
    const handleClearClick = (e) => {
      try {
        setAddProduct({
          productCode:'',
          productName:'',
          diamondShape: '',
          shapeCutting:'None',
          diamondColor:'None',
          diamondCarat:'',
          diamondClarity:'None',
          certificateNo:'',
          msrp:'',
          currency:'None',
      })
      } catch (error) {
        console.log(error)
      }
      
    };
    const [state, setState]=useState({
        profileImg:'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
    })
    const {profileImg} = state
  const  fileSelectedHandler= (event) =>{
        console.log(event.target.files[0])
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2)
            {
            setState({profileImg: reader.result})
        }
    }
    reader.readAsDataURL(event.target.files[0])
};
  return (
    <div>
        <Appbar/>
        <Sidebar/>
        <div className="DistributorContainer">
        <h2>Add Products</h2>
        <Box component="form"  sx={{'& > :not(style)': { m: 1, width: '35ch', mt:1 },}}noValidate autoComplete="off">
        <img className="ProductImg-Name" src={profileImg} id="img"/>
              <br/>
              <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="contained" component="label" onChange={fileSelectedHandler} style={{ background: 'rgb(64,64,64)' }}>
                Upload
                <input hidden accept="image/png, image/jpeg" multiple type="file"  onChange={e=> setImageData(e.target.files[0])}/>
             </Button>
              </Stack>
              <TextField id="outlined-basic" label="Product Code" variant="outlined" fullWidth value={addProduct.productCode}
                      onChange={e =>setAddProduct({...addProduct, productCode:e.target.value})} />
              <TextField id="outlined-basic" label="Product Name" variant="outlined" fullWidth value={addProduct.productName}
                    onChange={e =>setAddProduct({...addProduct, productName:e.target.value})}/>
                    <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Diamond Shape</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          label="Diamond Shape"
          value={addProduct.diamondShape}
          onChange={e =>setAddProduct({...addProduct, diamondShape:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"Round"}>Round</MenuItem>
          <MenuItem value={"Cushion"}>Cushion</MenuItem>
          <MenuItem value={"Princess"}>Princess</MenuItem>
          <MenuItem value={"Emerald"}>Emerald</MenuItem>
          <MenuItem value={"Radiant"}>Radiant</MenuItem>
          <MenuItem value={"Asscher"}>Asscher</MenuItem>
          <MenuItem value={"Pear"}>Pear</MenuItem>
          <MenuItem value={"Marquise"}>Marquise</MenuItem>
          <MenuItem value={"Heart"}>Heart</MenuItem>
        </Select>
        </FormControl>
        <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Shape Cutting</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          label="Shape Cutting"
          value={addProduct.shapeCutting}
          onChange={e =>setAddProduct({...addProduct, shapeCutting:e.target.value})} >
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"Good"}>Good</MenuItem>
          <MenuItem value={"Very Good"}>Very Good</MenuItem>
          <MenuItem value={"Prefect"}>Prefect</MenuItem>
          <MenuItem value={"Prefect Idea"}>Prefect Idea</MenuItem>
        </Select>
        </FormControl>
        <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Diamond Color</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          label="Diamond Color"
          value={addProduct.diamondColor}
                      onChange={e =>setAddProduct({...addProduct, diamondColor:e.target.value})} >
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"D"}>D</MenuItem>
          <MenuItem value={"E"}>E</MenuItem>
          <MenuItem value={"F"}>F</MenuItem>
          <MenuItem value={"G"}>G</MenuItem>
          <MenuItem value={"H"}>H</MenuItem>
          <MenuItem value={"I"}>I</MenuItem>
          <MenuItem value={"J"}>J</MenuItem>
          <MenuItem value={"K"}>K</MenuItem>
        </Select>
        </FormControl>
        
        <TextField id="outlined-basic" label="Dimaond Carat" variant="outlined" fullWidth value={addProduct.diamondCarat}
                      onChange={e =>setAddProduct({...addProduct, diamondCarat:e.target.value})} />
        <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Diamond Clarity</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          label="Diamond Clarity"
          value={addProduct.diamondClarity}
                      onChange={e =>setAddProduct({...addProduct, diamondClarity:e.target.value})} >
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"I1"}>I1</MenuItem>
          <MenuItem value={"SI2"}>SI2</MenuItem>
          <MenuItem value={"SI1"}>SI1</MenuItem>
          <MenuItem value={"VS2"}>VS2</MenuItem>
          <MenuItem value={"VS1"}>VS1</MenuItem>
          <MenuItem value={"VVS2"}>VVS2</MenuItem>
          <MenuItem value={"VVS1"}>VVS1</MenuItem>
          <MenuItem value={"I"}>I</MenuItem>
          <MenuItem value={"FI"}>FI</MenuItem>
        </Select>
        </FormControl>
         <TextField id="outlined-basic" label="Certificate No" variant="outlined" fullWidth value={addProduct.certificateNo}
                      onChange={e =>setAddProduct({...addProduct, certificateNo:e.target.value})}/>
        <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Currency</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          label="Currency"
          value={addProduct.currency}
                      onChange={e =>setAddProduct({...addProduct, currency:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"HKD"}>HKD</MenuItem>
          <MenuItem value={"RMB"}>RMB</MenuItem>
          <MenuItem value={"USD"}>USD</MenuItem>
          <MenuItem value={"RM"}>RM</MenuItem>
        </Select>
        </FormControl>
        <TextField id="outlined-basic" label="MSRP" variant="outlined" fullWidth value={addProduct.msrp}
                      onChange={e =>setAddProduct({...addProduct, msrp:e.target.value})} />
              </Box>
              <br/>
              <Stack spacing={2} direction="row" mb={5} ml={1}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Add</Button>
    <Button variant="contained" onClick={handleClearClick} style={{ background: 'rgb(64,64,64)' }}>Clear</Button>
   
    </Stack>
        </div>
    </div>
  )
}

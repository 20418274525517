import React, {useState, useEffect} from 'react'
import  Appbar  from '../../Appbar/Appbar'
import Sidebar from '../Sidebar/Sidebar'
import Box from '@mui/material/Box';
import {deleteOrderCart} from '../../../graphql/mutations'
import {listOrderCarts,listBillNotes,getAddDistributor} from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import { useParams } from 'react-router-dom'

export default function MyBillingInfo() {
  const{id} = useParams();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 15,
        },
      }));
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    const [billing, setBilling] = useState([]);
    useEffect(() => {
        fetchBilling();
      }, []);
      const fetchBilling = async ()=> {
        try{
          const BillingData = await API.graphql(graphqlOperation(listBillNotes));
          const BillingList = BillingData.data.listBillNotes.items;
          console.log('Billing List',BillingList );
          setBilling(BillingList);
        }catch(error)
        {
          console.log('error on fetching Billing', error);
        }
      };
      useEffect(() => {
        fetchDistributor();
      }, []);
      const [addDistributor,setAddDistributor]=useState({})
      const fetchDistributor = async () => {
        try {
        const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
        const DistribitorList = DistributorData.data.getAddDistributor;
        console.log(DistribitorList);
        setAddDistributor(DistribitorList);
        } catch (error) {
            console.log(error)
        }
      };
  return (
    <div> <Appbar item={id} name={addDistributor.firstname}/>
    <Sidebar item={id}/>
    <div className="SalesContainer">
    <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '100%',
          height: '100%',
        },
      }}>
         <h2>My Billing Info</h2>
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 50 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Order No</StyledTableCell>
            <StyledTableCell  style={{ width: 200 }} align="left">Bill No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Product Code</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Quantity</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Balance Amount</StyledTableCell>
            <StyledTableCell  style={{ width: 180 }} align="left">Register At</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {billing.map((Billing, idx) => {
          if(Billing.distributorNo === id)
          {
return (
            <>
             <StyledTableRow key={Billing.orderNo}>
             <StyledTableCell align="center">
             <Link to={"/distributorBillItem/"+Billing.id+"/"+id} className='link-menu'>
              <VisibilityIcon />
              </Link>&nbsp;&nbsp;&nbsp;&nbsp;
      </StyledTableCell>
      <StyledTableCell align="left">{Billing.orderNo}</StyledTableCell>
             <StyledTableCell align="left">{Billing.BillNo}</StyledTableCell>
             <StyledTableCell align="left">{Billing.productCode}</StyledTableCell>
             <StyledTableCell align="left">{Billing.quantity}</StyledTableCell>
             <StyledTableCell align="left">{Billing.balanceAmount}</StyledTableCell>
             <StyledTableCell align="left">{Billing.createdAt}</StyledTableCell>
             </StyledTableRow>
             </>
);
          }
 })}
        </TableBody>
      </Table>
      </TableContainer>
      </Box>
      </div>
    </div>
  )
}

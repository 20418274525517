import React, { useState, useEffect } from 'react';
import './admin.css'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Button} from '@mui/material';
import Stack from '@mui/material/Stack';
import { createAddAdmin,deleteAddAdmin} from '../../../../graphql/mutations';
import { API, graphqlOperation,Storage } from 'aws-amplify';
import {v4 as uuid} from 'uuid';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import {listAddAdmins} from '../../../../graphql/queries';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import Tooltip from '@mui/material/Tooltip';

export default function AddAdmin() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 15,
        },
      }));
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    const [addAdmin,setAddAdmin]=useState({})
    const handleClick= async()=>{
        try {
        const {firstname,lastname,username,password,confirmPassword,phoneNo,product,
            distributor,
            eCommerce,
            marketing,
            delivery,
            asm}=addAdmin;
        const addAdminInput = {
            id: uuid(),
            firstname,
            lastname,
            username,
            password,
            confirmPassword,
            phoneNo,
            product:'Not Specified',
            distributor:'Not Specified',
            eCommerce:'Not Specified',
            marketing:'Not Specified',
            delivery:'Not Specified',
            asm:'Not Specified'
        }
        await API.graphql(graphqlOperation(createAddAdmin, { input: addAdminInput }));
    alert('Admin Added Successfully');
    fetchAdmin();
} catch (error) {
    console.log(error);
}
    };
    const [admin, setAdmin] = useState([]);
    useEffect(() => {
        fetchAdmin();
      }, []);
      const fetchAdmin = async ()=> {
        try{
            const AdminData = await API.graphql(graphqlOperation(listAddAdmins));
        const AdminList = AdminData.data.listAddAdmins.items;
        console.log('Admin List',AdminList );
        setAdmin(AdminList);
        }
        catch(error)
        {
            console.log('error on fetching Distributor', error);
        }
    };
    const remove = async (Oid) => {
        try{
          const todoDetails = {
            id: Oid,
          };
          await API.graphql({ query: deleteAddAdmin, variables: {input: todoDetails}});
          alert('Record deleted successfully')
          fetchAdmin();
        }
        catch(error)
        {
          console.log(error);
        }
      };
  return (
    <div>
        <Appbar/>
    <Sidebar/>
        <div className="Container">
                <h2>Admin Registration</h2>
                <Box component="form" sx={{'& > :not(style)': { m: 1, width: '35ch', mt:1 }, }} noValidate autoComplete="off">
                <TextField id="outlined-basic" label="First Name" variant="outlined" inputProps={{
      style: {height: "15px",},}} value={addAdmin.firstname} onChange={e =>setAddAdmin({...addAdmin, firstname:e.target.value})}/>
      <TextField id="outlined-basic" label="Last Name" variant="outlined" inputProps={{
      style: {height: "15px",},}} value={addAdmin.lastname} onChange={e =>setAddAdmin({...addAdmin, lastname:e.target.value})}/>
      <br/>
      <TextField id="outlined-basic" label="User Name" variant="outlined" inputProps={{
      style: {height: "15px",},}} value={addAdmin.username} onChange={e =>setAddAdmin({...addAdmin, username:e.target.value})}/>
      <TextField id="outlined-basic" label="Password" variant="outlined"  type="password" inputProps={{
      style: {height: "15px",},}} value={addAdmin.password} onChange={e =>setAddAdmin({...addAdmin, password:e.target.value})}/>
      <TextField id="outlined-basic" label="Confirm Password" variant="outlined"  type="password" inputProps={{
      style: {height: "15px",},}} value={addAdmin.confirmPassword} onChange={e =>setAddAdmin({...addAdmin, confirmPassword:e.target.value})}/>
      <br/>
      <TextField id="outlined-basic" label="Phone No" variant="outlined" inputProps={{
      style: {height: "15px",},}} value={addAdmin.phoneNo} onChange={e =>setAddAdmin({...addAdmin, phoneNo:e.target.value})}/>
        </Box>
        <br/>
    <Stack spacing={2} direction="row" mb={2} ml={1}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Add</Button>
    <Button variant="contained" style={{ background: 'rgb(64,64,64)' }}>Clear</Button>
    </Stack>
        <h2>Admin List
            </h2>
    <div className='adminList'>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 140 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Name</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Phone No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Product</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Distributor</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">E-Commerce</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Marketing</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Delivery</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">ASM</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Register At</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {admin.map((Admin, idx) => {
return (
    <>
        <StyledTableRow key={Admin.id}>
        <StyledTableCell align="left"> 
             <Tooltip title="View"><Link to={"/AdminEdit/"+Admin.id} className='link-menu'>
              <VisibilityIcon/>
              </Link>
              </Tooltip>
             &nbsp;&nbsp;&nbsp;&nbsp;
             <Tooltip title="Delete">
             <DeleteIcon onClick={() =>remove(Admin.id)} className='link-menu'/>
             </Tooltip>
             </StyledTableCell>
             <StyledTableCell align="left">{Admin.firstname+' '+ Admin.lastname}</StyledTableCell>
             <StyledTableCell align="left">{Admin.phoneNo}</StyledTableCell>
             <StyledTableCell align="left">{Admin.product}</StyledTableCell>
             <StyledTableCell align="left">{Admin.distributor}</StyledTableCell>
             <StyledTableCell align="left">{Admin.eCommerce}</StyledTableCell>
             <StyledTableCell align="left">{Admin.marketing}</StyledTableCell>
             <StyledTableCell align="left">{Admin.delivery}</StyledTableCell>
             <StyledTableCell align="left">{Admin.asm}</StyledTableCell>
             <StyledTableCell align="left">{Admin.createdAt}</StyledTableCell>
            </StyledTableRow>
    </>
);
 })}
        </TableBody>
      </Table>
      </TableContainer>
    </div>
            </div>
    </div>
  )
}

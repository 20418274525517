/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAddproduct = /* GraphQL */ `
  mutation CreateAddproduct(
    $input: CreateAddproductInput!
    $condition: ModelAddproductConditionInput
  ) {
    createAddproduct(input: $input, condition: $condition) {
      id
      productCode
      productName
      diamondShape
      shapeCutting
      diamondColor
      diamondCarat
      diamondClarity
      measurement
      ratio
      certificateNo
      msrp
      currency
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateAddproduct = /* GraphQL */ `
  mutation UpdateAddproduct(
    $input: UpdateAddproductInput!
    $condition: ModelAddproductConditionInput
  ) {
    updateAddproduct(input: $input, condition: $condition) {
      id
      productCode
      productName
      diamondShape
      shapeCutting
      diamondColor
      diamondCarat
      diamondClarity
      measurement
      ratio
      certificateNo
      msrp
      currency
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddproduct = /* GraphQL */ `
  mutation DeleteAddproduct(
    $input: DeleteAddproductInput!
    $condition: ModelAddproductConditionInput
  ) {
    deleteAddproduct(input: $input, condition: $condition) {
      id
      productCode
      productName
      diamondShape
      shapeCutting
      diamondColor
      diamondCarat
      diamondClarity
      measurement
      ratio
      certificateNo
      msrp
      currency
      image
      createdAt
      updatedAt
    }
  }
`;
export const createAddDistributor = /* GraphQL */ `
  mutation CreateAddDistributor(
    $input: CreateAddDistributorInput!
    $condition: ModelAddDistributorConditionInput
  ) {
    createAddDistributor(input: $input, condition: $condition) {
      id
      distributorID
      photoID
      firstname
      lastname
      nicNo
      address1
      address2
      state
      country
      zipCode
      occupation
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      username
      password
      confirmPassword
      phoneNo
      bankName
      accountNo
      shiftCode
      bankAddress
      bankcountry
      email
      sponsorId_UL1
      sponsorName_UL1
      contactNo_UL1
      sponsorId_UL2
      sponsorName_UL2
      contactNo_UL2
      createdAt
      updatedAt
    }
  }
`;
export const updateAddDistributor = /* GraphQL */ `
  mutation UpdateAddDistributor(
    $input: UpdateAddDistributorInput!
    $condition: ModelAddDistributorConditionInput
  ) {
    updateAddDistributor(input: $input, condition: $condition) {
      id
      distributorID
      photoID
      firstname
      lastname
      nicNo
      address1
      address2
      state
      country
      zipCode
      occupation
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      username
      password
      confirmPassword
      phoneNo
      bankName
      accountNo
      shiftCode
      bankAddress
      bankcountry
      email
      sponsorId_UL1
      sponsorName_UL1
      contactNo_UL1
      sponsorId_UL2
      sponsorName_UL2
      contactNo_UL2
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddDistributor = /* GraphQL */ `
  mutation DeleteAddDistributor(
    $input: DeleteAddDistributorInput!
    $condition: ModelAddDistributorConditionInput
  ) {
    deleteAddDistributor(input: $input, condition: $condition) {
      id
      distributorID
      photoID
      firstname
      lastname
      nicNo
      address1
      address2
      state
      country
      zipCode
      occupation
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      username
      password
      confirmPassword
      phoneNo
      bankName
      accountNo
      shiftCode
      bankAddress
      bankcountry
      email
      sponsorId_UL1
      sponsorName_UL1
      contactNo_UL1
      sponsorId_UL2
      sponsorName_UL2
      contactNo_UL2
      createdAt
      updatedAt
    }
  }
`;
export const createAddAdmin = /* GraphQL */ `
  mutation CreateAddAdmin(
    $input: CreateAddAdminInput!
    $condition: ModelAddAdminConditionInput
  ) {
    createAddAdmin(input: $input, condition: $condition) {
      id
      firstname
      lastname
      username
      password
      confirmPassword
      phoneNo
      product
      distributor
      eCommerce
      marketing
      delivery
      asm
      createdAt
      updatedAt
    }
  }
`;
export const updateAddAdmin = /* GraphQL */ `
  mutation UpdateAddAdmin(
    $input: UpdateAddAdminInput!
    $condition: ModelAddAdminConditionInput
  ) {
    updateAddAdmin(input: $input, condition: $condition) {
      id
      firstname
      lastname
      username
      password
      confirmPassword
      phoneNo
      product
      distributor
      eCommerce
      marketing
      delivery
      asm
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddAdmin = /* GraphQL */ `
  mutation DeleteAddAdmin(
    $input: DeleteAddAdminInput!
    $condition: ModelAddAdminConditionInput
  ) {
    deleteAddAdmin(input: $input, condition: $condition) {
      id
      firstname
      lastname
      username
      password
      confirmPassword
      phoneNo
      product
      distributor
      eCommerce
      marketing
      delivery
      asm
      createdAt
      updatedAt
    }
  }
`;
export const createOrderCart = /* GraphQL */ `
  mutation CreateOrderCart(
    $input: CreateOrderCartInput!
    $condition: ModelOrderCartConditionInput
  ) {
    createOrderCart(input: $input, condition: $condition) {
      id
      orderNo
      distributorNo
      distributorName
      contactNo
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      productID
      productCode
      productName
      quantity
      amount
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderCart = /* GraphQL */ `
  mutation UpdateOrderCart(
    $input: UpdateOrderCartInput!
    $condition: ModelOrderCartConditionInput
  ) {
    updateOrderCart(input: $input, condition: $condition) {
      id
      orderNo
      distributorNo
      distributorName
      contactNo
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      productID
      productCode
      productName
      quantity
      amount
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderCart = /* GraphQL */ `
  mutation DeleteOrderCart(
    $input: DeleteOrderCartInput!
    $condition: ModelOrderCartConditionInput
  ) {
    deleteOrderCart(input: $input, condition: $condition) {
      id
      orderNo
      distributorNo
      distributorName
      contactNo
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      productID
      productCode
      productName
      quantity
      amount
      status
      createdAt
      updatedAt
    }
  }
`;
export const createPurchaseOrder = /* GraphQL */ `
  mutation CreatePurchaseOrder(
    $input: CreatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    createPurchaseOrder(input: $input, condition: $condition) {
      id
      orderNo
      purchaseOrder
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      balanceAmount
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const updatePurchaseOrder = /* GraphQL */ `
  mutation UpdatePurchaseOrder(
    $input: UpdatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    updatePurchaseOrder(input: $input, condition: $condition) {
      id
      orderNo
      purchaseOrder
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      balanceAmount
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const deletePurchaseOrder = /* GraphQL */ `
  mutation DeletePurchaseOrder(
    $input: DeletePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    deletePurchaseOrder(input: $input, condition: $condition) {
      id
      orderNo
      purchaseOrder
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      balanceAmount
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const createDeliveryNote = /* GraphQL */ `
  mutation CreateDeliveryNote(
    $input: CreateDeliveryNoteInput!
    $condition: ModelDeliveryNoteConditionInput
  ) {
    createDeliveryNote(input: $input, condition: $condition) {
      id
      deliveryNo
      billNo
      deliveryMethod
      shippingAddress
      courierServiceCompany
      DeliveryBillNo
      contactNo
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const updateDeliveryNote = /* GraphQL */ `
  mutation UpdateDeliveryNote(
    $input: UpdateDeliveryNoteInput!
    $condition: ModelDeliveryNoteConditionInput
  ) {
    updateDeliveryNote(input: $input, condition: $condition) {
      id
      deliveryNo
      billNo
      deliveryMethod
      shippingAddress
      courierServiceCompany
      DeliveryBillNo
      contactNo
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeliveryNote = /* GraphQL */ `
  mutation DeleteDeliveryNote(
    $input: DeleteDeliveryNoteInput!
    $condition: ModelDeliveryNoteConditionInput
  ) {
    deleteDeliveryNote(input: $input, condition: $condition) {
      id
      deliveryNo
      billNo
      deliveryMethod
      shippingAddress
      courierServiceCompany
      DeliveryBillNo
      contactNo
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const createSetupRules = /* GraphQL */ `
  mutation CreateSetupRules(
    $input: CreateSetupRulesInput!
    $condition: ModelSetupRulesConditionInput
  ) {
    createSetupRules(input: $input, condition: $condition) {
      id
      distributorML1
      distributorML2
      distributorML3
      dealerDL1
      dealerDL2
      dealerDL3
      GlobalDeposit
      OrderDuration
      TAX
      createdAt
      updatedAt
    }
  }
`;
export const updateSetupRules = /* GraphQL */ `
  mutation UpdateSetupRules(
    $input: UpdateSetupRulesInput!
    $condition: ModelSetupRulesConditionInput
  ) {
    updateSetupRules(input: $input, condition: $condition) {
      id
      distributorML1
      distributorML2
      distributorML3
      dealerDL1
      dealerDL2
      dealerDL3
      GlobalDeposit
      OrderDuration
      TAX
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetupRules = /* GraphQL */ `
  mutation DeleteSetupRules(
    $input: DeleteSetupRulesInput!
    $condition: ModelSetupRulesConditionInput
  ) {
    deleteSetupRules(input: $input, condition: $condition) {
      id
      distributorML1
      distributorML2
      distributorML3
      dealerDL1
      dealerDL2
      dealerDL3
      GlobalDeposit
      OrderDuration
      TAX
      createdAt
      updatedAt
    }
  }
`;
export const createBillNote = /* GraphQL */ `
  mutation CreateBillNote(
    $input: CreateBillNoteInput!
    $condition: ModelBillNoteConditionInput
  ) {
    createBillNote(input: $input, condition: $condition) {
      id
      orderNo
      BillNo
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      total
      tax
      deposit1
      deposit2
      deposit3
      balanceAmount
      fullAmount
      status
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const updateBillNote = /* GraphQL */ `
  mutation UpdateBillNote(
    $input: UpdateBillNoteInput!
    $condition: ModelBillNoteConditionInput
  ) {
    updateBillNote(input: $input, condition: $condition) {
      id
      orderNo
      BillNo
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      total
      tax
      deposit1
      deposit2
      deposit3
      balanceAmount
      fullAmount
      status
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteBillNote = /* GraphQL */ `
  mutation DeleteBillNote(
    $input: DeleteBillNoteInput!
    $condition: ModelBillNoteConditionInput
  ) {
    deleteBillNote(input: $input, condition: $condition) {
      id
      orderNo
      BillNo
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      total
      tax
      deposit1
      deposit2
      deposit3
      balanceAmount
      fullAmount
      status
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      couponCode
      couponCopy
      couponStartDate
      couponEndDate
      discountType
      cash
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      couponCode
      couponCopy
      couponStartDate
      couponEndDate
      discountType
      cash
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      couponCode
      couponCopy
      couponStartDate
      couponEndDate
      discountType
      cash
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const createBonus = /* GraphQL */ `
  mutation CreateBonus(
    $input: CreateBonusInput!
    $condition: ModelBonusConditionInput
  ) {
    createBonus(input: $input, condition: $condition) {
      id
      BillNo
      billAmount
      distributorName
      relation
      percentage
      bonusAmount
      BillCreationDate
      createdAt
      updatedAt
    }
  }
`;
export const updateBonus = /* GraphQL */ `
  mutation UpdateBonus(
    $input: UpdateBonusInput!
    $condition: ModelBonusConditionInput
  ) {
    updateBonus(input: $input, condition: $condition) {
      id
      BillNo
      billAmount
      distributorName
      relation
      percentage
      bonusAmount
      BillCreationDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteBonus = /* GraphQL */ `
  mutation DeleteBonus(
    $input: DeleteBonusInput!
    $condition: ModelBonusConditionInput
  ) {
    deleteBonus(input: $input, condition: $condition) {
      id
      BillNo
      billAmount
      distributorName
      relation
      percentage
      bonusAmount
      BillCreationDate
      createdAt
      updatedAt
    }
  }
`;
export const createAsm = /* GraphQL */ `
  mutation CreateAsm(
    $input: CreateAsmInput!
    $condition: ModelAsmConditionInput
  ) {
    createAsm(input: $input, condition: $condition) {
      id
      asmNo
      distributorNo
      distributorName
      orderNo
      contactNo
      certificateNo
      productCode
      productName
      serviceRequest
      serviceRemark
      billingDate
      estReadyDate
      deliveryMethod
      courierServiceCompany
      courierContactNo
      selfPickUpDate
      createdBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const updateAsm = /* GraphQL */ `
  mutation UpdateAsm(
    $input: UpdateAsmInput!
    $condition: ModelAsmConditionInput
  ) {
    updateAsm(input: $input, condition: $condition) {
      id
      asmNo
      distributorNo
      distributorName
      orderNo
      contactNo
      certificateNo
      productCode
      productName
      serviceRequest
      serviceRemark
      billingDate
      estReadyDate
      deliveryMethod
      courierServiceCompany
      courierContactNo
      selfPickUpDate
      createdBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteAsm = /* GraphQL */ `
  mutation DeleteAsm(
    $input: DeleteAsmInput!
    $condition: ModelAsmConditionInput
  ) {
    deleteAsm(input: $input, condition: $condition) {
      id
      asmNo
      distributorNo
      distributorName
      orderNo
      contactNo
      certificateNo
      productCode
      productName
      serviceRequest
      serviceRemark
      billingDate
      estReadyDate
      deliveryMethod
      courierServiceCompany
      courierContactNo
      selfPickUpDate
      createdBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const createCartData = /* GraphQL */ `
  mutation CreateCartData(
    $input: CreateCartDataInput!
    $condition: ModelCartDataConditionInput
  ) {
    createCartData(input: $input, condition: $condition) {
      id
      Image
      distributorID
      productID
      productName
      carat
      qty
      msrp
      currency
      createdAt
      updatedAt
    }
  }
`;
export const updateCartData = /* GraphQL */ `
  mutation UpdateCartData(
    $input: UpdateCartDataInput!
    $condition: ModelCartDataConditionInput
  ) {
    updateCartData(input: $input, condition: $condition) {
      id
      Image
      distributorID
      productID
      productName
      carat
      qty
      msrp
      currency
      createdAt
      updatedAt
    }
  }
`;
export const deleteCartData = /* GraphQL */ `
  mutation DeleteCartData(
    $input: DeleteCartDataInput!
    $condition: ModelCartDataConditionInput
  ) {
    deleteCartData(input: $input, condition: $condition) {
      id
      Image
      distributorID
      productID
      productName
      carat
      qty
      msrp
      currency
      createdAt
      updatedAt
    }
  }
`;

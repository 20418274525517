import React, { useState, useEffect } from 'react';
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import "./Distributor.css"
import { Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom'
import { API, graphqlOperation,Storage } from 'aws-amplify';
import { getAddDistributor} from '../../../../graphql/queries';

export default function DistributorItem() {
    const {id} = useParams();
    const [addDistributor,setAddDistributor]=useState({})
    const [DistributorImageURL, setDistributorImageURL] = useState('')

useEffect(() => {
    fetchDistributor();
  }, []);
  const fetchDistributor = async () => {
    try {
    const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
    const DistribitorList = DistributorData.data.getAddDistributor;
    console.log(DistribitorList);
    setAddDistributor(DistribitorList);
    const distributorFilePath = DistribitorList.photoID
          try
          {
              const fileAccessURL = await Storage.get(distributorFilePath, {expires:60})
              console.log('access url', fileAccessURL);
              setDistributorImageURL(fileAccessURL)
          }
          catch(error){
              console.error('error accessing the file from s3', error)
              setDistributorImageURL('');
          }
    } catch (error) {
        console.log(error)
    }
  };
const handleClick= async()=>{
    alert('Admin Cant update Distributor Details')
};
  return (
    <div>
      <Appbar/>
        <Sidebar/>
        <div className="DistributorContainer">
        <h2>Distributor Details</h2>
        <h3 className="DistributorNo">Distributor No : {addDistributor.id}</h3>
        <img className="ProductImg" src={DistributorImageURL} id="img"/>
      <h5 className="miniHeader">Personal Details</h5>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>First Name</label>
            <input type="text" placeholder="First Name" value={addDistributor.firstname}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" value={addDistributor.lastname}/>
        </div>
        <div className="newDeliveryItem1">
            <label>NIC No</label>
            <input type="text" placeholder="NIC No" value={addDistributor.nicNo}/>
        </div>
      </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Address 1</label>
            <input type="text" placeholder="Address 1" value={addDistributor.address1}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Address 2</label>
            <input type="text" placeholder="Address 2" value={addDistributor.address2}/>
        </div>
        <div className="newDeliveryItem1">
            <label>State</label>
            <input type="text" placeholder="State" value={addDistributor.state}/>
        </div>
      </div>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Country</label>
            <input type="text" placeholder="Country" value={addDistributor.country}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Zip Code</label>
            <input type="text" placeholder="Zip Code" value={addDistributor.zipCode}/>
        </div>
      </div>
      <div className="newDeliveryItem1">
            <label>Occupation</label>
            <input type="text" placeholder="Occupation" value={addDistributor.occupation}/>
        </div>
    <h5 className="miniHeader">Billing Details</h5>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Address 1</label>
            <input type="text" placeholder="Address 1" value={addDistributor.BillingAddress1}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Address 2</label>
            <input type="text" placeholder="Address 2" value={addDistributor.BillingAddress2}/>
        </div>
        <div className="newDeliveryItem1">
            <label>State</label>
            <input type="text" placeholder="State" value={addDistributor.BillingState}/>
        </div>
      </div>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Country</label>
            <input type="text" placeholder="Country" value={addDistributor.BillingCountry}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Zip Code</label>
            <input type="text" placeholder="Zip Code" value={addDistributor.BillingZipCode}/>
        </div>
      </div>
      <h5 className="miniHeader">Shipping Details</h5>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Address 1</label>
            <input type="text" placeholder="Address 1" value={addDistributor.shippingAddress1}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Address 2</label>
            <input type="text" placeholder="Address 2" value={addDistributor.shippingAddress2}/>
        </div>
        <div className="newDeliveryItem1">
            <label>State</label>
            <input type="text" placeholder="State" value={addDistributor.shippingState}/>
        </div>
      </div>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Country</label>
            <input type="text" placeholder="Country" value={addDistributor.shippingCountry}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Zip Code</label>
            <input type="text" placeholder="Zip Code" value={addDistributor.shippingZipCode}/>
        </div>
      </div>
    <h5 className="miniHeader">Login & Verification Details</h5>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>User Name</label>
            <input type="text" placeholder="User Name" value={addDistributor.username}/>
        </div>
    </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Phone Number</label>
            <input type="text" placeholder="Phone Number" value={addDistributor.phoneNo}/>
        </div>
        <div className="newDeliveryItem1">
            <label>E-Mail</label>
            <input type="text" placeholder="E-Mail" value={addDistributor.email}/>
        </div>
    </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Bank Name</label>
            <input type="text" placeholder="Bank Name" value={addDistributor.bankName}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Account No</label>
            <input type="text" placeholder="Account No" value={addDistributor.accountNo}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Shift Code</label>
            <input type="text" placeholder="Shift Code" value={addDistributor.shiftCode}/>
        </div>
    </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Bank Address</label>
            <input type="text" placeholder="Bank Address" value={addDistributor.bankAddress}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Account No</label>
            <input type="text" placeholder="Account No" value={addDistributor.bankcountry}/>
        </div>
    </div>
        <h5 className="miniHeader">Sponsor Details</h5>
        <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Sponsor ID(UL1)</label>
            <input type="text" placeholder="Sponsor ID(UL1)" value={addDistributor.sponsorId_UL1}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Sponsor's Name</label>
            <input type="text" placeholder="Sponsor's Name" value={addDistributor.sponsorName_UL1}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Contact Number</label>
            <input type="text" placeholder="Contact Number" value={addDistributor.contactNo_UL1}/>
        </div>
    </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Sponsor ID(UL2)</label>
            <input type="text" placeholder="Sponsor ID(UL2)" value={addDistributor.sponsorId_UL2}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Sponsor's Name</label>
            <input type="text" placeholder="Sponsor's Name" value={addDistributor.sponsorName_UL2}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Contact Number</label>
            <input type="text" placeholder="Contact Number" value={addDistributor.contactNo_UL2}/>
        </div>
    </div>
    <Stack spacing={2} direction="row" mb={5} ml={1} mt={2}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Update</Button>
    </Stack>
    </div>
    </div>
  )
}

import React from 'react'
import "./FeaturedInfo.css";
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import Box from '@mui/material/Box';

export default function featuredInfo() {
  return (
    <div className="FeatureContainer-1">
         <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 400,
          height: 228,
          mb: -5
        },
      }}
    >
       <Box p={1}>
      <div className="featuredItem">
            <span className="featuredTitle">
                My Order
            </span>
            <div className="featuredMoneyContainer">
            <span className="featuredMoney">0</span>
            <span className="featuredMoneyRate">
                 <ArrowUpward className="featuredIcon"/>
            </span>
            </div>
            <span className="featureSub">Compare to Last Month</span>
        </div>
     </Box>
      <Box p={1}>
      <div className="featuredItem">
            <span className="featuredTitle">
                My Billing
            </span>
            <div className="featuredMoneyContainer">
            <span className="featuredMoney">0</span>
            <span className="featuredMoneyRate">
                 <ArrowUpward className="featuredIcon"/>
            </span>
            </div>
            <span className="featureSub">Compare to Last Month</span>
        </div>
     </Box>
     
     <Box p={1}>
      <div className="featuredItem">
            <span className="featuredTitle">
                My Upline
            </span>
            <div className="featuredMoneyContainer">
            <span className="featuredMoney">0</span>
            <span className="featuredMoneyRate">
                 <ArrowUpward className="featuredIcon"/>
            </span>
            </div>
            <span className="featureSub">Compare to Last Month</span>
        </div>
     </Box>
     <Box p={1}>
      <div className="featuredItem">
            <span className="featuredTitle">
                My Downline
            </span>
            <div className="featuredMoneyContainer">
            <span className="featuredMoney">0</span>
            <span className="featuredMoneyRate">
                 <ArrowUpward className="featuredIcon"/>
            </span>
            </div>
            <span className="featureSub">Compare to Last Month</span>
        </div>
     </Box>
     <Box p={1}>
      <div className="featuredItem">
            <span className="featuredTitle">
                My Bonus
            </span>
            <div className="featuredMoneyContainer">
            <span className="featuredMoney">0</span>
            <span className="featuredMoneyRate">
                 <ArrowUpward className="featuredIcon"/>
            </span>
            </div>
            <span className="featureSub">Compare to Last Month</span>
        </div>
     </Box>
    </Box>
    </div>
   
  )
}
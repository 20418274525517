import './App.css';
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
import Home from './components/Home/home';
import Product from './components/Product/Products'
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
import AddProduct from './components/Admin/DashboardPages/Product/AddProduct'
import ProductListing from './components/Admin/DashboardPages/Product/ProductListing';
import AddDistributor from './components/Admin/DashboardPages/Distributor/AddDistributor';
import DistributorListing from './components/Admin/DashboardPages/Distributor/DistributorListing';
import DistributorItem from './components/Admin/DashboardPages/Distributor/DistributorItem';
import OrderCart from './components/Admin/DashboardPages/Sales/OrderCart'
import DeliveryNote from './components/Admin/DashboardPages/DeliveryNote/DeliveryNote'
import DeliveryNoteListing from './components/Admin/DashboardPages/DeliveryNote/DeliveryNoteListing'
import Invoice from './components/Admin/DashboardPages/Sales/Invoice';
import InvoiceListing from './components/Admin/DashboardPages/Sales/InvoiceListing';
import ProductTable from './components/Admin/DashboardPages/Product/ProductTable';
import Shopping from './components/Home/Shopping';
import DistributorProfile from './components/DistributorData/DistributorPages/DistributorProfile'
import DistributorOrder from './components/DistributorData/DistributorPages/YourOrder'
import YourCartItems from './components/DistributorData/DistributorPages/YourCartItems'
import MyBillingInfo from './components/DistributorData/DistributorPages/MyBillingInfo'
import DistributorBillItem from './components/DistributorData/DistributorPages/DistributorBillItem'
import DGroup from './components/DistributorData/DistributorPages/DGroup'
import DBonus from './components/DistributorData/DistributorPages/DBonus'
import Setup from './components/Admin/DashboardPages/Setup/Setup'
import AddAdmin from './components/Admin/DashboardPages/AdminReg/AddAdmin'
import AdminProfile from './components/Admin/AdminProfile';
import ProductItem from './components/Product/ProductItem';
import Coupon from './components/Admin/DashboardPages/Marketing/Coupon';
import CouponListing from './components/Admin/DashboardPages/Marketing/CouponListing';
import Payment from './components/Admin/DashboardPages/Payment/Payment';
import OnlinePayListing from './components/Admin/DashboardPages/Payment/OnlinePayListing';
import OfflinePayListing from './components/Admin/DashboardPages/Payment/OfflinePayListing';
import AfterSalesService from './components/Admin/DashboardPages/AfterSalesService/AfterSalesService';
import AfterSalesServicelisting from './components/Admin/DashboardPages/AfterSalesService/AfterSalesServicelisting';
import OrderCartListing from './components/Admin/DashboardPages/Sales/OrderCartListing';
import BillNote from './components/Admin/DashboardPages/Sales/BillNote';
import BillingListing from './components/Admin/DashboardPages/Sales/BillingListing';
import PaymentItem from './components/Home/Payment'
import FullPaymentItem from './components/Home/FullPayment'
import ProductAmend from './components/Admin/DashboardPages/Product/ProductAmend'
import OrderCartItem from './components/Admin/DashboardPages/Sales/OrderCartItem'
import BillingItem from './components/Admin/DashboardPages/Sales/BillingItem'
import DistributorBoard from './components/DistributorData/DistributorDashboard'
import PaymentGeneration from './components/Admin/DashboardPages/Sales/PaymentGeneration'
import ProductItemEdit from './components/Admin/DashboardPages/Product/ProductItem'
import AdminEdit from './components/Admin/DashboardPages/AdminReg/AdminEdit'
Amplify.configure(awsconfig);
function App({user}) {
  const Email = user.attributes.email
  const FirstName = user.attributes.name
  const Phonenumber = user.attributes.phone_number
  const ID = user.username
  
  return (
    <div className="App">
      <Router>
            <Routes>
                <Route exact path="/" element={<Home items={[Email,FirstName,Phonenumber,ID]} />}/>
                <Route exact path="/products/:id" element={<Product/>}/>
                <Route exact path="/admin/:id" element={<AdminLogin/>}/>
                <Route exact path='/shopping' element={<Shopping/>}/>
                <Route exact path='/profile/:id' element={<DistributorProfile/>}/>
                <Route exact path='/distributorDashboard/:id' element={<DistributorBoard/>}/>
                <Route exact path='YourOrder/:id' element={<DistributorOrder/>}/>
                <Route exact path='yourCartItems/:id/:type' element={<YourCartItems/>}/>
                <Route exact path='/MyBillingInfo/:id' element={<MyBillingInfo/>}/>
                <Route exact path='/distributorBillItem/:id/:type' element={<DistributorBillItem/>}/>
                <Route exact path='/DGroup/:id' element={<DGroup/>}/>
                <Route exact path='/DBonus/:id' element={<DBonus/>}/>
                <Route exact path='/paymentItem/:id/:type' element={<PaymentItem/>}/>
                <Route exact path='/fullpaymentItem/:id/:type' element={<FullPaymentItem/>}/>

                <Route exact path="/admindashboard" element={<AdminDashboard/>}/>
                <Route exact path="/setup" element={<Setup/>}/>
                <Route exact path='/adminProfile' element={<AdminProfile/>}/>
                <Route exact path='/addAdmin' element={<AddAdmin/>}/>
                <Route exact path='/adminedit/:id' element={<AdminEdit/>}/>
                
                <Route exact path="/addProduct" element={<AddProduct/>}/> 
                <Route exact path="/productListing" element={<ProductListing/>}/>
                <Route exact path='/productTable' element={<ProductTable/>}/>
                <Route exact path='/productitem/:id/:type' element={<ProductItem/>}/>
                <Route exact path='/productitemEdit/:id' element={<ProductItemEdit/>}/>
                <Route exact path='/productamend/:id' element={<ProductAmend/>}/>

                <Route exact path="/DistributorFullReg" element={<AddDistributor/>}/>
                <Route exact path="/distributorListing" element={<DistributorListing/>}/>
                <Route exact path='distributoritem/:id' element={<DistributorItem/>}/>

                <Route exact path="/sales" element={<OrderCart/>}/>
                <Route exact path='/OrdercartListing' element={<OrderCartListing/>}/>
                <Route exact path='/orderCartItems/:id' element={<OrderCartItem/>}/>
                <Route exact path='/BillNote' element={<BillNote/>}/>
                <Route exact path='/BillingListing' element={<BillingListing/>}/>
                <Route exact path='/billingItem/:id' element={<BillingItem/>}/>
                <Route exact path='/paymentGeneration/:id:Amt' element={<PaymentGeneration/>}/>
                <Route exact path="/createInvoice" element={<Invoice/>}/>
                <Route exact path="/invoiceListing" element={<InvoiceListing/>}/>

                <Route exact path='/createcoupon' element={<Coupon/>}/>
                <Route exact path='/couponlisting' element={<CouponListing/>}/>

                <Route exact path='/payment' element={<Payment/>}/>
                <Route exact path='/onlinepaymentListing' element={<OnlinePayListing/>}/>
                <Route exact path='/offlinepaymentListing' element={<OfflinePayListing/>}/>

                <Route exact path="/delivery" element={<DeliveryNote/>}/>
                <Route exact path="/deliveryListing" element={<DeliveryNoteListing/>}/>

                <Route exact path='/aftersalesservice' element={<AfterSalesService/>}/>
                <Route exact path='/afterSalesServicelisting' element={<AfterSalesServicelisting/>}/>
            </Routes>
      </Router>
    </div>
  );
}
export default withAuthenticator(App);
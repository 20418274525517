import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import { useParams } from 'react-router-dom'
import './Sales.css'

export default function PaymentGeneration() {
    const {id}= useParams();
    
  return (
    <div>
        <Appbar/>
    <Sidebar/>
    <div className='SalesContainer-0'>
        <h3>Payment Generation</h3>
        {id}
        <h2>
          For Second Payment we need to generate a PDF Doc or Link send the Distributor through E-Mail and Notification to the Distributor,</h2> 
          <h3>We are Pending on API/Payment Gateway to procced forward</h3>
          <br/>
          <br/>
          <h1 align='center'>Payment Gateway API 404 Error</h1>
        
        </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { listAddproducts} from '../../../../graphql/queries';
import {deleteAddproduct} from '../../../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './AddProduct.css'
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom"
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import Tooltip from '@mui/material/Tooltip';

export default function ProductTable() {
    const [product, setProducts] = useState([]);
    const [productImageURL, setproductImageURL] = useState('')
  useEffect(() => {
    fetchProduct();
  }, []);
  const fetchProduct = async () => {
      try {
          const productData = await API.graphql(graphqlOperation(listAddproducts));
          const productList = productData.data.listAddproducts.items;
          console.log('product list', productList);
          setProducts(productList);
      } catch (error) {
          console.log('error on fetching product', error);
      }
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/productListing');
};
const remove = async (Oid) => {
  try{
    const todoDetails = {
      id: Oid,
    };
    await API.graphql({ query: deleteAddproduct, variables: {input: todoDetails}});
    alert('Record deleted successfully')
    fetchProduct();
  }
  catch(error)
  {
    console.log(error);
  }
};
  return (
    <div>
        <Appbar/>
        <Sidebar/>
        <div className='DistributorContainer'>
        <h2>Product Table List</h2>
        {/* <Button className="table" variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Product listing</Button> */}
       
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 140 }} align="left">Action</StyledTableCell>
            <StyledTableCell style={{ width: 100 }} align="center">Image</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">Product Code</StyledTableCell>
            <StyledTableCell  style={{ width: 200 }} align="center">Product Name</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">Cut</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">Carat</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">Clarity</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="center">Color</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="center">Certificate</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="center">MSRP</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="center">Currency</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {product.map((Product, idx) => {
return (
            <>
             <StyledTableRow key={Product.productName}>
             <StyledTableCell align="left">
             <Tooltip title="Edit">
            <Link className='link-menu' to={"/productitemEdit/"+Product.id} className='link-menu1'>
            <CreateIcon />
            </Link>
            </Tooltip>
            <Tooltip title="Delete">
             <DeleteIcon onClick={() =>remove(Product.id)} className='link-menu1'/>
            </Tooltip>
             </StyledTableCell>
             <StyledTableCell align="center" component="th" scope="row">
             <Tooltip title="View">
             <Link to={"/productamend/"+Product.id}>
              <img className="ProductImg-Listing1" src={Product.image} alt="" />
            </Link>
            </Tooltip>
             </StyledTableCell>
             <StyledTableCell align="center">{Product.productCode}</StyledTableCell>
             <StyledTableCell align="center">{Product.productName}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondShape}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondCarat}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondClarity}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondColor}</StyledTableCell>
             <StyledTableCell align="center">{Product.certificateNo}</StyledTableCell>
             <StyledTableCell align="center">{Product.currency}</StyledTableCell>
             <StyledTableCell align="center">{Product.msrp}</StyledTableCell>
             </StyledTableRow>
             </>
);
 })}
        </TableBody>
        </Table>
        </TableContainer>
        </div>
    </div>

  )
}

import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Setup.css'
import Box from '@mui/material/Box';
import { Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { API, graphqlOperation } from 'aws-amplify';
import {createSetupRules,updateSetupRules,deleteSetupRules} from '../../../../graphql/mutations';
import {getSetupRules} from '../../../../graphql/queries';
import {v4 as uuid} from 'uuid';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import './Setup.css'
export default function Setup() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const [admin, setAdmin] = useState([]);
  useEffect(() => {
      fetchAdmin();
    }, []);
    const fetchAdmin = async ()=> {
      try{
          const AdminData = await API.graphql(graphqlOperation(getSetupRules, { id: '001b52d5-2d3d-4ed1-8a09-b91791ee96e0' }));
      const AdminList = AdminData.data.getSetupRules;
      console.log('Admin List',AdminList );
      setAdmin(AdminList);
      }
      catch(error)
      {
          console.log('error on fetching Distributor', error);
      }
  };
    const [SetupRules,setSetupRules]=useState({})
    const id = "001b52d5-2d3d-4ed1-8a09-b91791ee96e0";
    const handleClick= async()=>{
        console.log('SetupRulers',SetupRules)
        try {
        const {distributorML1,distributorML2,distributorML3,dealerDL1,dealerDL2,dealerDL3,GlobalDeposit,OrderDuration,TAX}=SetupRules;
        const createSetupRulesInput = {
          id: id,
          distributorML1,
          distributorML2,
          distributorML3,
          dealerDL1,
          dealerDL2,
          dealerDL3,
          GlobalDeposit,
          OrderDuration,
          TAX
          }
      await API.graphql(graphqlOperation(updateSetupRules, {input: createSetupRulesInput}));
      alert('Setup Rules Added Successfully');
        }catch(error){
          console.log('Setup Rules error', error);
        }
      };
  return (
    <div>
        <Appbar/>
        <Sidebar/>
        <div className="SetupContainer">
        <h2>Setup</h2>
        <h5 className="SetupminiHeader">Distributor Margin %</h5>
        <div className="DeliveryWidgetClient">
        <div className="newDeliveryItem">
            <label>Distributor Level 1</label>
            <input type="text" placeholder="Distributor Level 1" defaultValue={admin.distributorML1}
            onChange={e =>setSetupRules({...SetupRules, distributorML1:e.target.value})}
            />
        </div>
        <div className="newDeliveryItem">
            <label>Distributor Level 2</label>
            <input type="text" placeholder="Distributor Level 2" defaultValue={admin.distributorML2}
            onChange={e =>setSetupRules({...SetupRules, distributorML2:e.target.value})}
            />
        </div>
        <div className="newDeliveryItem">
            <label>Distributor Level 3</label>
            <input type="text" placeholder="Distributor Level 3" defaultValue={admin.distributorML3}
            onChange={e =>setSetupRules({...SetupRules, distributorML3:e.target.value})}
            />
        </div>
        </div>
        <h5 className="miniHeader">Dealer Discount %</h5>
        <div className="DeliveryWidgetClient">
        <div className="newDeliveryItem">
        <label>Dealer Level 1</label>
            <input type="text" placeholder="Dealer Level 1" defaultValue={admin.dealerDL1}
            onChange={e =>setSetupRules({...SetupRules, dealerDL1:e.target.value})}
            />
          </div>
          <div className="newDeliveryItem">
        <label>Dealer Level 2</label>
            <input type="text" placeholder="Dealer Level 2" defaultValue={admin.dealerDL2}
            onChange={e =>setSetupRules({...SetupRules, dealerDL2:e.target.value})}
            />
          </div>
          <div className="newDeliveryItem">
        <label>Dealer Level 3</label>
            <input type="text" placeholder="Dealer Level 3" defaultValue={admin.dealerDL3}
            onChange={e =>setSetupRules({...SetupRules, dealerDL2:e.target.value})}
            />
          </div>
          </div>
        <h5 className="miniHeader">Global Deposit</h5>
        <div className="DeliveryWidgetClient">
        <div className="newDeliveryItem">
        <label>Global Deposit</label>
        <select 
          defaultValue={admin.GlobalDeposit} onChange={e =>setSetupRules({...SetupRules, GlobalDeposit:e.target.value})}>
          <option value={"None"}>None</option>
          <option value={"5"}>5%</option>
          <option value={"10"}>10%</option>
          <option value={"15"}>15%</option>
          <option value={"20"}>20%</option>
          <option value={"25"}>25%</option>
          <option value={"30"}>30%</option>
          <option value={"35"}>35%</option>
          <option value={"40"}>40%</option>
          <option value={"45"}>45%</option>
          <option value={"50"}>50%</option>
          <option value={"55"}>55%</option>
          <option value={"60"}>60%</option>
          <option value={"65"}>65%</option>
          <option value={"70"}>70%</option>
          <option value={"75"}>75%</option>
          <option value={"80"}>80%</option>
          <option value={"85"}>85%</option>
          <option value={"90"}>90%</option>
          <option value={"95"}>95%</option>
        </select>
        
        </div>
        <div className="newDeliveryItem">
        <label>...</label>
        <input type="text" placeholder="" defaultValue={admin.GlobalDeposit} disabled/>
        </div>
        </div>
       
        <h5 className="miniHeader">TAX/VAT/GST %</h5>
        <div className="DeliveryWidgetClient">
        <div className="newDeliveryItem">
        <label>Tax/Vat/Gst</label>
            <input type="text" placeholder="Tax/Vat/Gst" defaultValue={admin.TAX}
            onChange={e =>setSetupRules({...SetupRules, TAX:e.target.value})}
            />
          </div>
          </div>
        <h5 className="miniHeader">Order Cut Off Time</h5>
        <div className="DeliveryWidgetClient">
        <div className="newDeliveryItem">
        <label>Order Duration</label>
        <select
          defaultValue={admin.OrderDuration} onChange={e =>setSetupRules({...SetupRules, OrderDuration:e.target.value})}>
          <option value={"None"}>None</option>
          <option value={"1 hour"}>1 hour</option>
          <option value={"2 hour"}>2 hour</option>
          <option value={"3 hour"}>3 hour</option>
          <option value={"4 hour"}>4 hour</option>
          <option value={"5 hour"}>5 hour</option>
          <option value={"6 hour"}>6 hour</option>
          <option value={"7 hour"}>7 hour</option>
          <option value={"8 hour"}>8 hour</option>
          <option value={"9 hour"}>9 hour</option>
          <option value={"10 hour"}>10 hour</option>
          <option value={"11 hour"}>11 hour</option>
          <option value={"12 hour"}>12 hour</option>
          <option value={"13 hour"}>13 hour</option>
          <option value={"14 hour"}>14 hour</option>
          <option value={"15 hour"}>15 hour</option>
          <option value={"16 hour"}>16 hour</option>
          <option value={"17 hour"}>17 hour</option>
          <option value={"18 hour"}>18 hour</option>
          <option value={"19 hour"}>19 hour</option>
          <option value={"20 hour"}>20 hour</option>
          <option value={"21 hour"}>21 hour</option>
          <option value={"22 hour"}>22 hour</option>
          <option value={"23 hour"}>23 hour</option>
          <option value={"24 hour"}>24 hour</option>
        </select>
        </div>
        <div className="newDeliveryItem">
        <label>...</label>
        <input type="text" placeholder="" defaultValue={admin.OrderDuration} disabled/>
        </div>
        </div>
        <Stack spacing={2} direction="row" mb={5} ml={1} mt={5}>
        <Button variant="contained" component="label" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Save</Button>
        </Stack>
        </div>
    </div>
  )
}

import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '../Typography';


const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  mr: 1,
  '&:hover': {
    bgcolor: 'white',
  },
};
export default function Footer() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'rgb(64,64,64)' }}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
      </Container>
    </Typography>
  );
}

import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Sales.css'

export default function Invoice() {
  return (
    <div>
    <Appbar/>
    <Sidebar/>
    <div className="SalesContainer">
        Invoice
    </div>
    </div>
  )
}

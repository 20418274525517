import React, { useState, useEffect } from 'react';
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import "./Distributor.css"
import { Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { createAddDistributor} from '../../../../graphql/mutations';
import { API, graphqlOperation,Storage } from 'aws-amplify';
import {v4 as uuid} from 'uuid';

export default function AddDistributor() {
    const [addDistributor,setAddDistributor]=useState({})
    const [imageData,setImageData]=useState()
    const [state, setState]=useState({
      profileImg:'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
  })
  const {profileImg} = state
const  fileSelectedHandler= (event) =>{
      console.log(event.target.files[0])
      const reader = new FileReader();
      reader.onload = () =>{
          if(reader.readyState === 2)
          {
          setState({profileImg: reader.result})
      }
  }
  reader.readAsDataURL(event.target.files[0])
};
      const handleClick= async()=>{
      const {key} = await Storage.put(`${uuid()}.jpg`,imageData, {contentType:'image/jpg'});
      const { firstname,lastname,nicNo,address1,address2,state,country,zipCode,occupation, shippingAddress1,
        shippingAddress2,
        shippingState,
        shippingCountry,
        shippingZipCode,
        BillingAddress1,
        BillingAddress2,
        BillingState,
        BillingCountry,
        BillingZipCode,username,password,
        confirmPassword,phoneNo,email,bankName,accountNo,shiftCode,
        bankAddress,bankcountry,sponsorId_UL1,
        sponsorName_UL1,
        contactNo_UL1,
        sponsorId_UL2,
        sponsorName_UL2,
        contactNo_UL2,}=addDistributor;
      const addDistributorInput = {
        id: uuid(),
        firstname,
        lastname,
        nicNo,
        address1,
        address2,
        state,
        country,
        zipCode,
        occupation,
        shippingAddress1,
  shippingAddress2,
  shippingState,
  shippingCountry,
  shippingZipCode,
  BillingAddress1,
  BillingAddress2,
  BillingState,
  BillingCountry,
  BillingZipCode,
        username,
        password,
        confirmPassword,
        phoneNo,
        email,
        bankName,
        accountNo,
        shiftCode,
        bankAddress,
        bankcountry,
        sponsorId_UL1,
        sponsorName_UL1,
        contactNo_UL1,
        sponsorId_UL2,
        sponsorName_UL2,
        contactNo_UL2,
        photoID: key,
      }
    await API.graphql(graphqlOperation(createAddDistributor, { input: addDistributorInput }));
    alert('Distributor Added Successfully');
    };
   
  return (  
    <div>
        <Appbar/>
        <Sidebar/>
        <div className="DistributorContainer">
        <h2>Distributor Registration</h2>
        <h3 className="DistributorNo">Distributor No : </h3>
        <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '35ch', mt:1 },
      }}
      noValidate
      autoComplete="off">
        <img className="ProductImg" src={profileImg} id="img"/>
              <br/>
              <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="contained" component="label" onChange={fileSelectedHandler} style={{ background: 'rgb(64,64,64)' }}>
                Upload
                <input hidden accept="image/png, image/jpeg" multiple type="file"  onChange={e=> setImageData(e.target.files[0])}/>
             </Button>
              </Stack>
      <h5 className="miniHeader">Personal Details</h5>
      <TextField id="outlined-basic" label="First Name" variant="outlined" inputProps={{
      style: {height: "15px",},}} value={addDistributor.firstname} onChange={e =>setAddDistributor({...addDistributor, firstname:e.target.value})}/>
      <TextField id="outlined-basic" label="Last Name" variant="outlined" inputProps={{
      style: {height: "15px",},}}value={addDistributor.lastname} onChange={e =>setAddDistributor({...addDistributor, lastname:e.target.value})}/>
      <TextField id="outlined-basic" label="NIC No" variant="outlined" inputProps={{
      style: {height: "15px",},}}value={addDistributor.nicNo}
      onChange={e =>setAddDistributor({...addDistributor, nicNo:e.target.value})}/>
    <br/>
      <TextField id="outlined-basic" label="Address 1" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.address1}
    onChange={e =>setAddDistributor({...addDistributor, address1:e.target.value})}/>
      <TextField id="outlined-basic" label="Address 2" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.address2}
    onChange={e =>setAddDistributor({...addDistributor, address2:e.target.value})}/>
      <FormControl>
       <InputLabel  id="demo-simple-select-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="State"
          value={addDistributor.state}
          onChange={e =>setAddDistributor({...addDistributor, state:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"Shanghai Municipality"}>Shanghai Municipality</MenuItem>
        </Select>
        </FormControl>
     <FormControl>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="Country"
          value={addDistributor.country}
onChange={e =>setAddDistributor({...addDistributor, country:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"China"}>China</MenuItem>
          <MenuItem value={"Hong Kong"}>Hong Kong</MenuItem>
          <MenuItem value={"India"}>India</MenuItem>
        </Select>
        </FormControl>
        <TextField id="outlined-basic" label="Zip Code" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}
    value={addDistributor.zipCode}
onChange={e =>setAddDistributor({...addDistributor, zipCode:e.target.value})}/>
<br/>
 <FormControl>
              <InputLabel id="demo-simple-select-label">Occupation</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="Occupationr"
          value={addDistributor.occupation}
onChange={e =>setAddDistributor({...addDistributor, occupation:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"Public Sector"}>Public Sector</MenuItem>
          <MenuItem value={"Private Sector"}>Private Sector</MenuItem>
          <MenuItem value={"Freelancer"}>Freelancer</MenuItem>
          <MenuItem value={"Student"}>Student</MenuItem>
        </Select>
        </FormControl>
    <br/>
    <h5 className="miniHeader">Billing Address</h5>
    <TextField id="outlined-basic" label="Address 1" variant="outlined" inputProps={{style: {height: "15px",},}}
    value={addDistributor.BillingAddress1}
    onChange={e =>setAddDistributor({...addDistributor, BillingAddress1:e.target.value})}/>
    <TextField id="outlined-basic" label="Address 2" variant="outlined" inputProps={{style: {height: "15px",},}}
    value={addDistributor.BillingAddress2}
    onChange={e =>setAddDistributor({...addDistributor, BillingAddress1:e.target.value})}/>
    <FormControl>
       <InputLabel  id="demo-simple-select-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="State"
          value={addDistributor.BillingState}
          onChange={e =>setAddDistributor({...addDistributor, BillingState:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"Shanghai Municipality"}>Shanghai Municipality</MenuItem>
        </Select>
        </FormControl>
     <FormControl>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="Country"
          value={addDistributor.BillingCountry}
          onChange={e =>setAddDistributor({...addDistributor, BillingCountry:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"China"}>China</MenuItem>
          <MenuItem value={"Hong Kong"}>Hong Kong</MenuItem>
          <MenuItem value={"India"}>India</MenuItem>
        </Select>
        </FormControl>
        <TextField id="outlined-basic" label="Zip Code" variant="outlined" inputProps={{style: {height: "15px",},}}
        value={addDistributor.BillingZipCode}
        onChange={e =>setAddDistributor({...addDistributor, BillingZipCode:e.target.value})}/>
        <br/>
        <h5 className="miniHeader">Shipping Address</h5>
    <TextField id="outlined-basic" label="Address 1" variant="outlined" inputProps={{style: {height: "15px",},}}
    value={addDistributor.shippingAddress1}
    onChange={e =>setAddDistributor({...addDistributor, shippingAddress1:e.target.value})}/>
    <TextField id="outlined-basic" label="Address 2" variant="outlined" inputProps={{style: {height: "15px",},}}
    value={addDistributor.shippingAddress2}
    onChange={e =>setAddDistributor({...addDistributor, shippingAddress2:e.target.value})}/>
    <FormControl>
       <InputLabel  id="demo-simple-select-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="State"
          value={addDistributor.shippingState}
          onChange={e =>setAddDistributor({...addDistributor, shippingState:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"Shanghai Municipality"}>Shanghai Municipality</MenuItem>
        </Select>
        </FormControl>
     <FormControl>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="Country"
          value={addDistributor.shippingCountry}
          onChange={e =>setAddDistributor({...addDistributor, shippingCountry:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
          <MenuItem value={"China"}>China</MenuItem>
          <MenuItem value={"Hong Kong"}>Hong Kong</MenuItem>
          <MenuItem value={"India"}>India</MenuItem>
        </Select>
        </FormControl>
        <TextField id="outlined-basic" label="Zip Code" variant="outlined" inputProps={{style: {height: "15px",},}}
        value={addDistributor.shippingZipCode}
        onChange={e =>setAddDistributor({...addDistributor, shippingZipCode:e.target.value})}/>
        <br/>
    <h5 className="miniHeader">Login & Verification Details</h5>
    <TextField id="outlined-basic" label="User Name" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.username}
    onChange={e =>setAddDistributor({...addDistributor, username:e.target.value})}/>
    <br/>
      <TextField id="outlined-basic" label="Password" type="password" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.password}
    onChange={e =>setAddDistributor({...addDistributor, password:e.target.value})}/>
      <TextField id="outlined-basic" label="Confirm Password" type="password" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.confirmPassword}
    onChange={e =>setAddDistributor({...addDistributor, confirmPassword:e.target.value})}/>
    <br/>
      <TextField id="outlined-basic" label="Phone Number" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.phoneNo}
    onChange={e =>setAddDistributor({...addDistributor, phoneNo:e.target.value})}/>
      <TextField id="outlined-basic" label="Email ID" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.email}
    onChange={e =>setAddDistributor({...addDistributor, email:e.target.value})}/>
    <br/>
      <TextField id="outlined-basic" label="Bank Name" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.bankName}
    onChange={e =>setAddDistributor({...addDistributor, bankName:e.target.value})}/>
      <TextField id="outlined-basic" label="Account Number" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.accountNo}
    onChange={e =>setAddDistributor({...addDistributor, accountNo:e.target.value})}/>
     <TextField id="outlined-basic" label="Shift Code" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.shiftCode}
    onChange={e =>setAddDistributor({...addDistributor, shiftCode:e.target.value})}/>
    <br/>
      <TextField id="outlined-basic" label="Bank Address" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.bankAddress}
    onChange={e =>setAddDistributor({...addDistributor, bankAddress:e.target.value})}/>
       <FormControl>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="Disselect"
          label="Country" value={addDistributor.bankcountry}
          onChange={e =>setAddDistributor({...addDistributor, bankcountry:e.target.value})}>
          <MenuItem value={"None"}>None</MenuItem>
        </Select>
        </FormControl>
        <h5 className="miniHeader">Sponsor Details</h5>
    <TextField id="outlined-basic" label="Sponsor ID(UL1)" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.sponsorId_UL1}
    onChange={e =>setAddDistributor({...addDistributor, sponsorId_UL1:e.target.value})}/>
     <TextField id="outlined-basic" label="Sponsor's Name" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.sponsorName_UL1}
    onChange={e =>setAddDistributor({...addDistributor, sponsorName_UL1:e.target.value})}/>
     <TextField id="outlined-basic" label="Contact Number" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.contactNo_UL1}
    onChange={e =>setAddDistributor({...addDistributor, contactNo_UL1:e.target.value})}/>
     <TextField id="outlined-basic" label="Sponsor ID(UL2)" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.sponsorId_UL2}
    onChange={e =>setAddDistributor({...addDistributor, sponsorId_UL2:e.target.value})}/>
     <TextField id="outlined-basic" label="Sponsor's Name" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.sponsorName_UL2}
    onChange={e =>setAddDistributor({...addDistributor, sponsorName_UL2:e.target.value})}/>
     <TextField id="outlined-basic" label="Contact Number" variant="outlined" inputProps={{
      style: {
        height: "15px",
      },
    }}value={addDistributor.contactNo_UL12}
    onChange={e =>setAddDistributor({...addDistributor, contactNo_UL2:e.target.value})}/>
    </Box>
    <br/>
    <Stack spacing={2} direction="row" mb={5} ml={1}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Add</Button>
    <Button variant="contained" style={{ background: 'rgb(64,64,64)' }}>Clear</Button>
    </Stack>
 
    </div>
    </div>
  )
}

import React, {useState, useEffect} from 'react'
import "./Payment.css"
import Box from '@mui/material/Box';
import Appbar from '../Appbar/Appbar'
import {Button} from '@mui/material';
import {v4 as uuid} from 'uuid';
import { API, graphqlOperation,Storage } from 'aws-amplify';
import {createBillNote} from '../../graphql/mutations';
import { useParams } from 'react-router-dom'
import { getOrderCart,getAddproduct,getSetupRules,getAddDistributor} from '../../graphql/queries';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {useNavigate} from "react-router-dom";

export default function FullPayment() {
const {id}= useParams();
const {type} = useParams();

const [disable, setDisable] = React.useState(false);

const [product, setProducts] = useState([]);

const [orderCart, setOrderCart] = useState([]);
const [setup,setSetup] = useState([]);
var TaxVal = 0.0;
var totalAmt= 0.0;
var depositAmt= 0.0;
var balAmt= 0.0;
useEffect(() => {
  fetchProduct();
}, []);
const fetchProduct = async () => {
    try {
        const OrderData = await API.graphql(graphqlOperation(getOrderCart, { id: id }));
        const OrderList = OrderData.data.getOrderCart;
        console.log(OrderList);
        setOrderCart(OrderList);

        const productData = await API.graphql(graphqlOperation(getAddproduct, { id: OrderList.productID }));
            const productList = productData.data.getAddproduct;
            console.log(productList);
            setProducts(productList);
    try{
      const SetupData = await API.graphql(graphqlOperation(getSetupRules,{id:'001b52d5-2d3d-4ed1-8a09-b91791ee96e0'}))
      const SetupList = SetupData.data.getSetupRules;
      console.log(SetupList);
      setSetup(SetupList);
    }
    catch (error){
      console.log('error on fetching Setup Rules', error);
    }
  }
  catch (error){
    console.log('error on fetching Order Cart', error);
  }
  };
  const navigate = useNavigate();
  const [addBilling,setAddBilling]=useState([])
  const onPay=async()=>{
    try {
      setDisable(true)
      const {orderNo,BillNo,distributorNo,distributorName,contactNo,
        productCode,quantity,total,tax,deposit1,deposit2,deposit3,balanceAmount,fullAmount,status}=addBilling
        const addBillingInput = {
          id:uuid(),
          orderNo:orderCart.orderNo,
          BillNo:uuid(),
          distributorNo:orderCart.distributorNo,
          distributorName:orderCart.distributorName,
          contactNo:orderCart.contactNo,
          productCode:product.productCode,
          quantity:orderCart.quantity,
          total:totalAmt,
          tax:TaxVal,
          deposit1:'0',
          deposit2:'0',
          deposit3:'0',
          balanceAmount:'0',
          fullAmount:balAmtWithTax,
          status:'Paid'
        }
        await API.graphql(graphqlOperation(createBillNote, { input: addBillingInput })); 
        alert('Deposit is Paid, Pending admin to confirm')
        navigate('/');
    } catch (error) {
      console.log(error)
    }
    
  };
  var DisDistributor = ((parseFloat(20)/100) * parseFloat(orderCart.amount)).toFixed(2)
  totalAmt = (parseFloat(orderCart.amount) - DisDistributor).toFixed(2)
  depositAmt = ((parseFloat(totalAmt))*(parseFloat(setup.GlobalDeposit)/100)).toFixed(2)
  TaxVal = ((parseFloat(setup.TAX)/100) * parseFloat(totalAmt)).toFixed(2)
  var balAmtWithTax = (parseInt(totalAmt) + parseFloat(TaxVal)).toFixed(2)
  const navigateToFullPay = useNavigate();
  const handleClick_1=(e)=>{
        e.preventDefault();
        if(e.target.style.background==='green')
        {
          
        }
        else
        {
           document.getElementById("fullpayment").style.backgroundColor='rgb(160,160,160)';
           e.target.style.background = 'green'
           navigateToFullPay('/paymentItem/'+id+'/'+type);
        }
    };
    useEffect(() => {
      fetchDistributor();
    }, []);
    const [addDistributor,setAddDistributor]=useState({})
    const fetchDistributor = async () => {
      try {
      const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: type }));
      const DistribitorList = DistributorData.data.getAddDistributor;
      console.log(DistribitorList);
      setAddDistributor(DistribitorList);
      } catch (error) {
          console.log(error)
      }
    };
    const BillingAddress = orderCart.BillingAddress1 +", "+ orderCart.BillingAddress2+", "+orderCart.BillingState+", "+orderCart.BillingCountry +", "+orderCart.BillingZipCode
    const ShippingAddress = orderCart.shippingAddress1 +", "+ orderCart.shippingAddress2+", "+orderCart.shippingState+", "+orderCart.shippingCountry +", "+orderCart.shippingZipCode
  return (
    <div> 
        <Appbar item={type} name={addDistributor.firstname}/>
        <div className='SalesContainer'>
        <div className="OrderWidgetClient1">
    <div className="newOrderItem1">
        <h3>Order Information</h3>
        <Box component="form" sx={{'& > :not(style)': { m: 1, mt:1 },}}noValidate autoComplete="off">
        <h4 className="paymentOption">Your Order Items</h4>
        <div className='fontfamily-o'>
        Order No: {orderCart.orderNo}
        </div>
        <br/>
        <img className="ProductImg-Shop" src={product.image} alt="" />
        <br/>
        <div className='fontfamily-o'>
        <table>
  <tr>
    <td>Product Name</td>
    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.productName}</td>
  </tr>
  <tr>
  <td>Quantity</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{orderCart.quantity}</td>
  </tr>
  <tr>
  <td>Shape</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondShape}</td>
  </tr>
  <tr>
  <td>Cutting</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.shapeCutting}</td>
  </tr>
  <tr>
  <td>Clarity</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondClarity}</td>
  </tr>
  <tr>
  <td>Carat</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondCarat}</td>
  </tr>
  <tr>
  <td>Color</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondColor}</td>
  </tr>
  <tr>
  <td>MSRP</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{orderCart.amount}</td>
  </tr>
</table>
        </div>
        </Box>
        </div>
        <div className="newOrderItem1">
        <h3>Distributor Information</h3>
        <Box component="form" sx={{'& > :not(style)': { m: 1, mt:1 },}}noValidate autoComplete="off">
        <div className='fontfamily-o'>
        Distributor Name: {orderCart.distributorName}
        <br/>
        Contact No: {orderCart.contactNo}
        </div>

        <div className="newDeliveryItem1">
            <label>Billing Address</label>
            <textarea value={BillingAddress} rows="3" style={{ width: 400,fontSize:17,borderRadius:5}}/>
            <label>Shipping Address</label>
            <textarea value={ShippingAddress} rows="3" style={{ width: 400,fontSize:17,borderRadius:5}}/>
        </div>
        </Box>
    </div>
    <div className="newOrderItem1">
      <h3>Payment Information</h3>
      <Box component="form" sx={{'& > :not(style)': { m: 1, width: '36ch', mt:1 }, }} noValidate autoComplete="off">
      <Box sx={{ display: 'flex', gap: 2 }}>
      <Button id='deposit' variant="contained" style={{ background: 'rgb(160,160,160)', width:150}} onClick={handleClick_1}>Deposit</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button id='fullpayment' variant="contained" style={{background: 'green' }}>Full Payment</Button>
    </Box>
      <table>
  <tr>
  <td>MSRP</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {product.msrp}.00</td>
  </tr>
  <tr>
  <td>Distributor 20%</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {DisDistributor}</td>
  </tr>
  <tr>
  <td><hr/></td>
  <td><hr/></td>
  </tr>
  <tr>
  <td>Total</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {totalAmt}</td>
  </tr>
  <tr>
  <td>Tax {setup.TAX}% ({totalAmt})</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {TaxVal}</td>
  </tr>
  <tr>
  <td><hr/></td>
  <td><hr/></td>
  </tr>
  <tr>
  <td>Balance Amount</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {balAmtWithTax}</td>
  </tr>
  </table>
  <br/>
  <br/>
  <Button variant="contained" style={{ background: 'rgb(64,64,64)' ,marginLeft:50, height:60}} onClick={() =>onPay() } disabled={disable}>Pay</Button>
</Box>
      </div>
</div>
 </div>
  </div>
  )
}

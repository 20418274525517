import React from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Content from './components/Home/Content'
import reportWebVitals from './reportWebVitals';
import Footer from './components/Home/Footer'
import ProductValue from './components/Home/ProductValues'
import ProductCategories from './components/Home/ProductCategories'
import ProductHowItWorks from "./components/Home/ProductHowItsWorks";
const handleClick= ()=>{
  root.render(
    <React.StrictMode>
<App/>
</React.StrictMode>
);
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppBar position="sticky" style={{ background: 'rgb(64,64,64)' }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
         DD Lab Grown Diamond
        </Typography>
        <Button onClick={handleClick} color="inherit">Login</Button>
      </Toolbar>
    </AppBar>
    <Content/>
    <ProductValue/>
        <ProductCategories/>
        <ProductHowItWorks/>
       <Footer/>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

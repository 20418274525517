import React, { useState, useEffect } from 'react';
import { listAddproducts,getAddDistributor} from '../../graphql/queries';
import { API, graphqlOperation} from 'aws-amplify';
import Appbar from '../Appbar/Appbar'
import { Container, Button} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './Products.css'
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import {Link} from "react-router-dom"
import { useParams } from 'react-router-dom'

export default function Products() {
  const{id} = useParams();
  const [product, setProducts] = useState([]);
    const [productImageURL, setproductImageURL] = useState('')
  useEffect(() => {
    fetchProduct();
  }, []);
  const fetchProduct = async () => {
      try {
          const productData = await API.graphql(graphqlOperation(listAddproducts));
          const productList = productData.data.listAddproducts.items;
          console.log('product list', productList);
          setProducts(productList);
      } catch (error) {
          console.log('error on fetching product', error);
      }
      
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
    const handleSearch= () => {
        alert(product.image);
    };
    const [formats, setFormats] = React.useState(() => ['button']);
    const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    setFormats(newFormats);
  };
  useEffect(() => {
    fetchDistributor();
  }, []);
  const [addDistributor,setAddDistributor]=useState({})
  const fetchDistributor = async () => {
    try {
    const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
    const DistribitorList = DistributorData.data.getAddDistributor;
    console.log(DistribitorList);
    setAddDistributor(DistribitorList);
    } catch (error) {
        console.log(error)
    }
  };
  return (
    <div>
    <Appbar item={id} name={addDistributor.firstname}/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div className="container">
    <Container>
  <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 100 }} align="center">Image</StyledTableCell>
            <StyledTableCell  style={{ width: 200 }} align="center">Product Name</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">Product Code</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center"> Cut</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center"> Carat</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center"> Clarity</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center"> Color</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">Certificate No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">MSRP</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="center">Currency</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {product.map((Product, idx) => {
          console.log(Product.image)
return (
            <>
             <StyledTableRow key={Product.productName}>
             <StyledTableCell align="center" component="th" scope="row">
             <Link to={"/productItem/"+Product.id+"/"+id}>
              <img className="ProductImg-Listing1" src={Product.image} alt="" />
              </Link>
             </StyledTableCell>
             <StyledTableCell align="center">{Product.productName}</StyledTableCell>
             <StyledTableCell align="center">{Product.productCode}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondShape}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondCarat}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondClarity}</StyledTableCell>
             <StyledTableCell align="center">{Product.diamondColor}</StyledTableCell>
             <StyledTableCell align="center">{Product.certificateNo}</StyledTableCell>
             <StyledTableCell align="center">{Product.msrp}</StyledTableCell>
             <StyledTableCell align="center">{Product.currency}</StyledTableCell>
             </StyledTableRow>
             </>
);
 })}
        </TableBody>
        </Table>
        </TableContainer>
        </Container>
    </div>
    </div>
  )
}

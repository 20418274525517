/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://a7nu5u5uhvejvh26eq42ctfszy.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wevypwceezfrdgtf4xld43uvgm",
    "aws_cognito_identity_pool_id": "ap-south-1:6c9e9cd0-dd8a-4043-982f-9bb00b7c809a",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_V972guJb3",
    "aws_user_pools_web_client_id": "2c8uo4si9jg813trjoe89mes3b",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ddlabgrown84ac6c2a3ab84883a6849eb712f8ec65223853-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;

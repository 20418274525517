import React, {useState, useEffect} from 'react'
import  Appbar  from '../../Appbar/Appbar'
import Sidebar from '../Sidebar/Sidebar'
import Box from '@mui/material/Box';
import {deleteOrderCart} from '../../../graphql/mutations'
import {listOrderCarts,listBillNotes,getAddDistributor} from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import { useParams } from 'react-router-dom'

export default function YourOrder() {
  const{id} = useParams();
  useEffect(() => {
    fetchDistributor();
  }, []);
  const [addDistributor,setAddDistributor]=useState({})
  const fetchDistributor = async () => {
    try {
    const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
    const DistribitorList = DistributorData.data.getAddDistributor;
    console.log(DistribitorList);
    setAddDistributor(DistribitorList);
    } catch (error) {
        console.log(error)
    }
  };
  const [orderList, setOrderList] = useState([]);
  useEffect(() => {
    fetchOrder();
    }, []);
    const fetchOrder = async ()=> {
      try{
        const OrderData = await API.graphql(graphqlOperation(listOrderCarts));
        const OrderList = OrderData.data.listOrderCarts.items;
        console.log('Order List',OrderList );
        setOrderList(OrderList);
      }catch(error)
      {
        console.log('error on fetching Order Cart', error);
      }
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
      },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
    const remove = async (Oid) => {
      try{
        const todoDetails = {
          id: Oid,
        };
        const deletedTodo = await API.graphql({ query: deleteOrderCart, variables: {input: todoDetails}});
        alert('Record deleted successfully')
        fetchOrder();
      }
      catch(error)
      {
        console.log(error);
      }
    };
    
  return (
    <div>
         <Appbar item={id} name={addDistributor.firstname}/>
      <Sidebar item={id}/>
      <div className="SalesContainer">
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '100%',
          height: '100%',
        },
      }}>
     <h2>My Order Info</h2>
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 50 }} align="center">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Order No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Distributor Name</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Product Name</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Quantity</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Amount</StyledTableCell>
            <StyledTableCell  style={{ width: 50 }} align="left">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {orderList.map((OrderList, idx) => {
          if(OrderList.distributorNo === id)
          {
return (
            <>
            <StyledTableRow key={OrderList.orderNo}>
             <StyledTableCell align="center">
             <Link to={"/YourCartItems/"+OrderList.id+"/"+id} className='link-menu'>
             <VisibilityIcon />
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp; 
      <DeleteIcon onClick={() =>remove(OrderList.id)} className='link-menu'/>
      </StyledTableCell>
             <StyledTableCell align="left">{OrderList.orderNo}</StyledTableCell>
             <StyledTableCell align="left">{OrderList.distributorName}</StyledTableCell>
             <StyledTableCell align="left">{OrderList.productName}</StyledTableCell>
             <StyledTableCell align="left">{OrderList.quantity}</StyledTableCell>
             <StyledTableCell align="left">{OrderList.amount}</StyledTableCell>
             <StyledTableCell align="left">{OrderList.status}</StyledTableCell>
             </StyledTableRow>
             </>
);
}
 })}
        </TableBody>
      </Table>
      </TableContainer>
      </Box>
      </div>
    </div>
  )
}

import Appbar from './Appbar/Appbar'
import './home.css'
import React, { useState, useEffect } from 'react';
import { listAddproducts} from '../../graphql/queries';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Link} from "react-router-dom"


export default function Shopping() 
{ const [items, setItems] = useState([]);
  const [name, setName] = useState([]);
  const [cartItems, setCartItems] = useState([]);
    const [product, setProducts] = useState([]);
  const [productImageURL, setproductImageURL] = useState([])
useEffect(() => {
  fetchProduct();
}, []);
const fetchProduct = async () => {
    try {
        const productData = await API.graphql(graphqlOperation(listAddproducts));
        const productList = productData.data.listAddproducts.items;
        console.log('product list', productList);
        setProducts(productList);
        const productFilePath = productList.image
        try
        {
            const fileAccessURL = await Storage.get(productFilePath)
            setproductImageURL(fileAccessURL)
        }
        catch(error){
            console.error('error accessing the file from s3', error)
            setproductImageURL('');
        }
    } catch (error) {
        console.log('error on fetching product', error);
    }
};
const [itemCount, setItemCount] =useState(0);
const [Dcarat,setDcarat]=useState([]);
      const [Dmsrp,setDmsrp]=useState([]);
      const [currency,setCurrency]=useState([]);
      const [productid,setProductid]=useState([]);
      const [productCode,setproductCode]=useState([]);
      const onAdd = (image,Productid,Productname,carat,msrp,currency,ProductCode)=>{
        setItemCount(itemCount + 1)
    setItems(image);
    setName(Productname);
    setDcarat(carat);
    setDmsrp(msrp);
    setCurrency(currency)
    setProductid(Productid)
    setproductCode(ProductCode)
       };
       const gg = async (id)=>{
        
       };
  return (
    <div>
    <div className="sticky">
    <Appbar items={[items,name,Dcarat,Dmsrp,currency,productid,productCode,itemCount]}/>
    </div>
    <br/>
    <br/>
    <br/>
    <div className="ShoppingContainer">  
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 270,
          height: 'fit-content',
          
        },
      }}
    >
{product.map((Product, idx) => {
 return (
      <>       
       <Box p={1} key={`Product${idx}`}>
      <div className="ProductItem">
      <Link to={"/productitem/"+Product.id}>
      <img className="ProductImg-Listing" src={''} alt="" />
      </Link>
      <br/>
      <h4>{Product.productName}</h4>
      <br/>
      Shape: {Product.diamondShape}
      <br/>
      Carat: {Product.diamondCarat}
      <br/>
      MSRP: {Product.msrp} {Product.currency}
      <br/>
      <br/>
      <Button className="table" variant="contained" onClick={() =>onAdd(productImageURL,Product.id,Product.productName,Product.diamondCarat,Product.msrp,Product.currency,Product.productCode)} style={{ background: 'rgb(64,64,64)' }}>Add to cart</Button>
      </div>
     </Box>
     </>
 );
})}
</Box>
     </div>
   </div>
  )
}

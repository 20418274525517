import React from 'react'
import Sidebar from './Sidebar/Sidebar'
import Appbar from './Appbar/Appbar'
import FeaturedInfo from './DashboardPages/HomePage/FeaturedInfo'
export default function AdminDashboard() {
  return (
    <div>
        <Appbar/>
        <Sidebar/>
        <FeaturedInfo/>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { listAddproducts} from '../../../../graphql/queries';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './AddProduct.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom"
export default function ProductListing() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/productTable');
};

  const [product, setProducts] = useState([]);
  const [productImageURL, setproductImageURL] = useState('')
useEffect(() => {
  fetchProduct();
}, []);
const fetchProduct = async () => {
    try {
        const productData = await API.graphql(graphqlOperation(listAddproducts));
        const productList = productData.data.listAddproducts.items;
        console.log('product list', productList);
        setProducts(productList);
        
        const productFilePath = 'diamond2.jpg';
        try
        {
            const fileAccessURL = await Storage.get(productFilePath, {expires:60})
            console.log('access url', fileAccessURL);
            setproductImageURL(fileAccessURL)
        }
        catch(error){
            console.error('error accessing the file from s3', error)
            setproductImageURL('');
        }
    } catch (error) {
        console.log('error on fetching product', error);
    }
};
  return (
    <div>
        <Appbar/>
        <Sidebar/>
        <div className="DistributorContainer">  
<h2>Product List</h2>
<Button className="table" variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>
  Product Table
</Button>
<Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 270,
          height: 'fit-content',
          
        },
      }}
    >
{product.map((Product, idx) => {
  
 return (
      <>       
       <Box p={1}>
      <div className="ProductItem">
        
      <Link to={"/productamend/"+Product.id}>
      <img className="ProductImg-Listing" src={productImageURL} alt="" />
      </Link>
      <br/>
      {Product.productName}
      <br/>
      {Product.productCode}
      <br/>
      {Product.diamondShape}
      <br/>
      {Product.diamondCarat}
      </div>
      
     </Box>
     
     </>
    
 );
})}
</Box>

</div>
        </div>
   
  )
}

import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Sales.css'
export default function BillNote() {
  return (
    <div> <Appbar/>
    <Sidebar/>
    <div className="SalesContainer">
        Billing
    </div>
    </div>
  )
}

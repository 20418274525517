import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Appbar from '../Home/Appbar/Appbar'
import { getAddproduct,getAddDistributor,listCartData} from '../../graphql/queries';
import {createCartData} from '../../graphql/mutations'
import { API, graphqlOperation,Storage } from 'aws-amplify';
import './Products.css'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Badge from "@material-ui/core/Badge";
import Footer from '../Home/Footer'
import {v4 as uuid} from 'uuid';
export default function ProductItem() {
  
  const [items, setItems] = useState([]);
  const [name, setName] = useState([]);
  const [product, setProducts] = useState([]);
  const [productImageURL, setproductImageURL] = useState('')
    const {id,type}= useParams();

    console.log(type)
    useEffect(() => {
      fetchDistributor();
    }, []);
    const [addDistributor,setAddDistributor]=useState({})
    const fetchDistributor = async () => {
      try {
      const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: type }));
      const DistribitorList = DistributorData.data.getAddDistributor;
      console.log(DistribitorList);
      setAddDistributor(DistribitorList);
      } catch (error) {
          console.log(error)
      }
    };
    useEffect(() => {
      fetchProduct();
      fetchCart();
    }, []);
    const [cartList, setCartList]=useState([])
    const fetchCart = async() =>{
      try {
        const cartData = await API.graphql(graphqlOperation(listCartData));
          const cartList = cartData.data.listCartData.items;
          console.log('Cart list', cartList);
          setCartList(cartList);
      } catch (error) {
        console.log(error)
      }
    };
    const fetchProduct = async () => {
        try {
            const productData = await API.graphql(graphqlOperation(getAddproduct, { id: id }));
            const productList = productData.data.getAddproduct;
            console.log(productList);
            setProducts(productList);
        }
        catch (error){
          console.log('error on fetching product', error);
        }
      };
      const [itemCount, setItemCount] =useState(0);
      const [Dcarat,setDcarat]=useState([]);
      const [Dmsrp,setDmsrp]=useState([]);
      const [currency,setCurrency]=useState([]);
      const [productid,setProductid]=useState([]);
      const [productCode,setproductCode]=useState([]);
      const onAdd = async(image,Productid,Productname,carat,msrp,currency,ProductCode)=>{
        setItemCount(itemCount + 1)
        setItems(image);
        setName(Productname);
        setDcarat(carat);
        setDmsrp(msrp);
        setCurrency(currency)
        setProductid(Productid)
        setproductCode(ProductCode)
        try {
          const createAddCartInput = {
            id: uuid(),
            Image:image,
            distributorID:type,
            productID:Productid,
            productName:Productname,
            carat:carat,
            qty:'1',
            msrp:msrp,
            currency:currency
          }
          await API.graphql(graphqlOperation(createCartData, {input: createAddCartInput}));
        }
        catch(error)
        {
          console.log(error)
        }
       };
  return (
    <div>
        <Appbar items={[items,name,Dcarat,Dmsrp,currency,productid,productCode,itemCount,type,addDistributor.firstname]}/>
      <br/>
      <br/>
      <br/>
      <div className="ShoppingContainer"> 
      <img className="ProductImg" src={product.image} alt="" />
      <br/>
      <Button className="table" variant="contained" style={{ background: 'rgb(64,64,64)' }}  onClick={() =>onAdd(product.image,product.id,product.productName,product.diamondCarat,product.msrp,product.currency,product.productCode)} >Add to cart</Button>
     <br/>
     <br/>
<table>
  <tr>
    <td>MSRP </td>
    <td>{product.msrp} {product.currency}</td>
  </tr>
  </table>
      <h2>Diamond Details</h2>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Product Code</label>
            <input type="text" placeholder="Product Code" defaultValue={product.productCode}
            disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Product Name</label>
            <input type="text" placeholder="Product Name" defaultValue={product.productName}
            disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Shape</label>
            <input type="text" placeholder="Diamond Shape" defaultValue={product.diamondShape}
          disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Cutting</label>
            <input type="text" placeholder="Cutting" defaultValue={product.shapeCutting}
          disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Color</label>
            <input type="text" placeholder="Diamond Color" defaultValue={product.diamondColor}
            disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Carat</label>
            <input type="text" placeholder="Diamond Carat" defaultValue={product.diamondCarat}
           disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Diamond Clarity</label>
            <input type="text" placeholder="Diamond Clarity" defaultValue={product.diamondClarity}
            disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Measurements</label>
            <input type="text" placeholder="Measurement" defaultValue={product.measurement}
            disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Length/Width Ratio</label>
            <input type="text" placeholder="Ratio" defaultValue={product.ratio}
            disabled/>
        </div>
        <div className="newDeliveryItem1">
            <label>Certificate No</label>
            <input type="text" placeholder="Certificate No" defaultValue={product.certificateNo}
            disabled/>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}

import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './AfterSales.css'
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import Tooltip from '@mui/material/Tooltip';
import {listAsms} from '../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import {deleteAsm} from '../../../../graphql/mutations'

export default function AfterSalesServicelisting() {
  const [amsNote, setAmsNote] = useState([]);
  useEffect(() => {
    fetchASMNote();
  }, []);
  const fetchASMNote = async ()=> {
    try{
      const ASMData = await API.graphql(graphqlOperation(listAsms));
      const ASMList = ASMData.data.listAsms.items;
      console.log('DeliveryNote List',ASMList );
      setAmsNote(ASMList);
    }catch(error)
    {
      console.log('error on fetching DeliveryNote', error);
    }
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const remove = async (Oid) => {
    try{
      const todoDetails = {
        id: Oid,
      };
      await API.graphql({ query: deleteAsm, variables: {input: todoDetails}});
      alert('Record deleted successfully')
      fetchASMNote();
    }
    catch(error)
    {
      console.log(error);
    }
  };
  return (
    <div>
    <Appbar/>
    <Sidebar/>
    <div className='AfterContainer'>
    <h2>After Sales Maintenance Listing</h2>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 100 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">ASM No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Bill No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Distributor No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Product Code</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Service Request</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Delivery Method</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Courier Company</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="left">Create By</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {amsNote.map((AMSNote, idx) => {
return (
            <>
             <StyledTableRow key={AMSNote.productName}>
             <StyledTableCell align="left"> 
             <Tooltip title="View"><Link to={"/couponItem/"+AMSNote.id} className='link-menu'>
              <VisibilityIcon/>
              </Link>
              </Tooltip>
             &nbsp;&nbsp;&nbsp;&nbsp;
             <Tooltip title="Delete">
             <DeleteIcon onClick={() =>remove(AMSNote.id)} className='link-menu'/>
             </Tooltip>
             </StyledTableCell>
             <StyledTableCell align="left">{AMSNote.deliveryNo}</StyledTableCell>
             <StyledTableCell align="left">{AMSNote.billNo}</StyledTableCell>
             <StyledTableCell align="left">{AMSNote.deliveryMethod}</StyledTableCell>
             <StyledTableCell align="left">{AMSNote.shippingAddress}</StyledTableCell>
             <StyledTableCell align="left">{AMSNote.courierServiceCompany}</StyledTableCell>
             <StyledTableCell align="left">{AMSNote.DeliveryBillNo}</StyledTableCell>
             <StyledTableCell align="left">{AMSNote.contactNo}</StyledTableCell>
             <StyledTableCell align="left">{AMSNote.createBy}</StyledTableCell>
             </StyledTableRow>
             </>
);
 })}
        </TableBody>
      </Table>
      </TableContainer>
    </div>
    </div>
  )
}

import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Sales.css'
export default function OrderCart() {
  return (
    <div>
    <Appbar/>
    <Sidebar/>
    <div className="DistributorContainer">
        Order Cart
    </div>
    </div>
  )
}

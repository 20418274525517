import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import React,{useState, useEffect } from "react";
import './Appbar.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PopUp from './PopUp';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import {useNavigate} from "react-router-dom";
import { createOrderCart,deleteCartData} from '../../../graphql/mutations';
import { getAddDistributor,listCartData} from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import {v4 as uuid} from 'uuid';
import Badge from "@material-ui/core/Badge";
import {deepPurple } from '@mui/material/colors';
import { Authenticator } from '@aws-amplify/ui-react';
import DeleteIcon from '@mui/icons-material/Delete';


export default function Appbar(props) {
  
  const items = props.items[0];
  const Name = props.items[1]
  const carat = props.items[2]
  const msrp = props.items[3]
  const currency = props.items[4]
  const productID = props.items[5]
  const ProductCode = props.items[6]
  const itemCount = props.items[7]
  const DisID = props.items[8]
  const Disname = props.items[9]

  const navigate = useNavigate();
    const [buttonPopup,setButtonPopup] = useState(false)
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    const cartClick = () =>{
      fetchCart();
      setButtonPopup(true)
    };
    useEffect(() => {
      fetchDistributor();
      fetchCart();
    }, []);
    const [cartList, setCartList]=useState([])
    const fetchCart = async() =>{
      try {
        const cartData = await API.graphql(graphqlOperation(listCartData));
          const cartList = cartData.data.listCartData.items;
          console.log('Cart list', cartList);
          setCartList(cartList);
      } catch (error) {
        console.log(error)
      }
    };
    const id =DisID
    const [distributor, setDistributor] = useState([]);
    const fetchDistributor = async () => {
        try {
            const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
            const DistributorList = DistributorData.data.getAddDistributor;
            console.log(DistributorList);
            setDistributor(DistributorList);
        }
        catch (error){
          console.log('error on fetching product', error);
        }
      };
    const [orderCart,setOrderCart]=useState({})
    const HandlePayment = async() =>{
      try {
        const {orderNo,distributorNo,distributorName,contactNo,BillingAddress1,BillingAddress2,
          BillingState,BillingCountry,BillingZipCode,shippingAddress1,shippingAddress2,
          shippingState,shippingCountry,shippingZipCode,productCode,productName,quantity,amount,status}=orderCart
        const addOrderCartInput = {
          id:uuid(),
          orderNo:uuid(),
          distributorNo:distributor.id,
          distributorName:distributor.firstname+ ' '+distributor.lastname,
          contactNo:distributor.phoneNo,
          BillingAddress1:distributor.BillingAddress1,
          BillingAddress2:distributor.BillingAddress2,
          BillingState:distributor.BillingState,
          BillingCountry:distributor.BillingCountry,
          BillingZipCode:distributor.BillingZipCode,
          shippingAddress1:distributor.shippingAddress1,
          shippingAddress2:distributor.shippingAddress2,
          shippingState:distributor.shippingState,
          shippingCountry:distributor.shippingCountry,
          shippingZipCode:distributor.shippingZipCode,
          productID:productID,
          productCode:ProductCode,
          productName:Name,
          quantity:itemCount,
          amount:msrp*itemCount+' '+currency,
          status:'None'}
        await API.graphql(graphqlOperation(createOrderCart, { input: addOrderCartInput })); 
        alert('Order Generated');
        navigate('/paymentItem/'+addOrderCartInput.id+'/'+DisID);
      } catch (error) {
        console.log(error)
      }
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
      },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,

      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
    const remove = async (Oid) => {
      try{
        const todoDetails = {
          id: Oid,
        };
        const deletedTodo = await API.graphql({ query: deleteCartData, variables: {input: todoDetails}});
        fetchCart();
      }
      catch(error)
      {
        console.log(error);
      }
    };
    return (
      <>
      <AppBar position="fixed" style={{ background: 'rgb(64,64,64)' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 10,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Link to={"/"} className='link'>
              DD Lab Grown Diamond
              </Link>
             </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem>
                     <Link to={"/products/"+DisID} className='link-menu'>
                    <Typography  textAlign="left">Product</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to={"/Admin/"+DisID} className='link-menu'>
                    <Typography  textAlign="left">Admin</Typography>
                    </Link>
                  </MenuItem>
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Link to={"/"} className='link'>
              DD Lab Grown Diamond
              </Link>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Link to={"/Products/"+DisID} className="link">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Product
                 </Button>
                </Link>
                <Link to={"/Admin/"+DisID} className="link">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Admin
                 </Button>
                </Link>
            </Box>
            <Tooltip title="Cart">
            <Badge color="secondary" badgeContent={itemCount} onClick={cartClick} className='mybadge'>
            <ShoppingCartIcon fontSize="large" onClick={cartClick} className='shopping-icon'/>
            </Badge>
            </Tooltip>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar className='shopping-Avatar' sx={{ bgcolor: deepPurple[500] }}>{Disname}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Link to={"/Profile/"+DisID} className="link-menu">
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                  </Link>
                  <MenuItem onClick={handleCloseUserMenu}>
                  <Authenticator>
                  {({ signOut, user }) => (
                  <Typography textAlign="center" onClick={signOut}>Logout</Typography>
                  )}</Authenticator>
                  </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        <PopUp trigger={buttonPopup} setTrigger={setButtonPopup}>
        <h3 color='black'>My Order Cart</h3>
        <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
          <StyledTableCell style={{ width: 20 }} align="center">Action</StyledTableCell>
            <StyledTableCell style={{ width: 20 }} align="center">Image</StyledTableCell>
            <StyledTableCell  style={{ width: 20 }} align="center">Product Name</StyledTableCell>
            <StyledTableCell  style={{ width: 20 }} align="center">Diamond Carat</StyledTableCell>
            <StyledTableCell  style={{ width: 20 }} align="center">Quantity</StyledTableCell>
            <StyledTableCell  style={{ width: 20 }} align="center">MSRP</StyledTableCell>
            <StyledTableCell  style={{ width: 20 }} align="center">Currency</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {cartList.map((cart, idx) => {
          if(cart.distributorID === DisID)
          {
          return(
             <StyledTableRow>
              <StyledTableCell align="center">
              <Tooltip title='Delete'>
      <DeleteIcon onClick={() =>remove(cart.id)} className='link-menu'/>
      </Tooltip>
              </StyledTableCell>
             <StyledTableCell component="th" scope="row" align="center"><img className="ProductImg-Listing1" src={cart.Image} alt="" /></StyledTableCell>
             <StyledTableCell align="center">{cart.productName}</StyledTableCell>
             <StyledTableCell align="center">{cart.carat}</StyledTableCell>
             <StyledTableCell align="center">{cart.qty}</StyledTableCell>
             <StyledTableCell align="center">{cart.msrp*cart.qty}</StyledTableCell>
             <StyledTableCell align="center">{cart.currency}</StyledTableCell>
             </StyledTableRow>
             );
          }
            })}
        </TableBody>
        </Table>
        </TableContainer>
        <br/>
        <br/>
        <div><Button variant="contained" onClick={() =>HandlePayment()} style={{ background: 'rgb(64,64,64)' }}>Procced</Button></div>
        </PopUp>
      </AppBar>
     
      </>
    );
  }
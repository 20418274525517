import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Delivery.css'
import {listDeliveryNotes} from '../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import {deleteDeliveryNote} from '../../../../graphql/mutations'
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import Tooltip from '@mui/material/Tooltip';
export default function DeliveryNoteListing() {
    const [deliveryNote, setDeliveryNote] = useState([]);
    useEffect(() => {
      fetchDeliveryNote();
    }, []);
    const fetchDeliveryNote = async ()=> {
        try{
          const DeliveryData = await API.graphql(graphqlOperation(listDeliveryNotes));
          const DeliveryList = DeliveryData.data.listDeliveryNotes.items;
          console.log('DeliveryNote List',DeliveryList );
          setDeliveryNote(DeliveryList);
        }catch(error)
        {
          console.log('error on fetching DeliveryNote', error);
        }
      };
      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 12,
        },
      }));
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      const remove = async (Oid) => {
        try{
          const todoDetails = {
            id: Oid,
          };
          await API.graphql({ query: deleteDeliveryNote, variables: {input: todoDetails}});
          alert('Record deleted successfully')
          fetchDeliveryNote();
        }
        catch(error)
        {
          console.log(error);
        }
      };
  return (
    <div>
    <Appbar/>
    <Sidebar/>
    <div className="DistributorContainer">
       <h2>Delivery Listing</h2>
       <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 160 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Delivery No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Bill No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Delivery Method</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Shipping to</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Courier by</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Airway Bill No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Contact No</StyledTableCell>
            <StyledTableCell  style={{ width: 180 }} align="left">Create By</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {deliveryNote.map((DeliveryNote, idx) => {
return (
            <>
             <StyledTableRow key={DeliveryNote.productName}>
             <StyledTableCell align="left"> 
             <Tooltip title="View"><Link to={"/couponItem/"+DeliveryNote.id} className='link-menu'>
              <VisibilityIcon/>
              </Link>
              </Tooltip>
             &nbsp;&nbsp;&nbsp;&nbsp;
             <Tooltip title="Delete">
             <DeleteIcon onClick={() =>remove(DeliveryNote.id)} className='link-menu'/>
             </Tooltip>
             </StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.deliveryNo}</StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.billNo}</StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.deliveryMethod}</StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.shippingAddress}</StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.courierServiceCompany}</StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.DeliveryBillNo}</StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.contactNo}</StyledTableCell>
             <StyledTableCell align="left">{DeliveryNote.createBy}</StyledTableCell>
             </StyledTableRow>
             </>
);
 })}
        </TableBody>
      </Table>
      </TableContainer>
    </div>
    </div>
  )
}

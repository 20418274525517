import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Payment.css'

export default function OfflinePayListing() {
  return (
    <div><Appbar/>
    <Sidebar/>
    <div className='PaymentContainer'>
    Offline Payment
    </div>
    </div>
  )
}

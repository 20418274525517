import React, { useState, useEffect } from 'react';
import './admin.css'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import { useParams } from 'react-router-dom'

export default function AdminEdit() {
    const {id} = useParams();
  return (
    <div> <Appbar/>
    <Sidebar/>
    <div className="Container">
    {id}
    <h2>Data will be updated Soon </h2>
    </div>
    </div>
  )
}

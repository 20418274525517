import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Sales.css'
import { API, graphqlOperation } from 'aws-amplify';
import { getBillNote} from '../../../../graphql/queries';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useNavigate} from "react-router-dom";

export default function BillingItem() {
    const [addBill,setAddBill]=useState({})
    const {id}= useParams();
    const [billNote, setBillNote] = useState([]);
    const [deposit2, setdeposit2] = useState('');
    const [balance, setBalance] = useState('');
    useEffect(() => {
        fetchBilling();
      }, []);
      const fetchBilling = async () => {
        try {
            const BillData = await API.graphql(graphqlOperation(getBillNote, { id: id }));
        const BillList = BillData.data.getBillNote;
        console.log(BillList);
        setBillNote(BillList);
        } catch (error) {
            console.log(error)
        }
      };
      const navigate = useNavigate();
      const handlePay= ()=>{
        alert('Forwarding to generate balance bill amount')
        navigate('/paymentGeneration/'+billNote.id);
      };
      const navigateDistributor_Enter= (e) => {
        if (e.key === 'Enter') {
      console.log(parseInt(billNote.balanceAmount) - parseInt(deposit2))
      setBalance(parseInt(billNote.balanceAmount) - parseInt(deposit2))
        }
      };
      const handleClick_1=(e)=>{
        e.preventDefault();
        if(e.target.style.background==='green')
        {
           e.target.style.background = 'rgb(160,160,160)'
           document.getElementById("fullpayment").style.backgroundColor= 'green';
        }
        else
        {
           document.getElementById("fullpayment").style.backgroundColor='rgb(160,160,160)';
           e.target.style.background = 'green'
        }
    };
    const handleClick_2=(e)=>{
        e.preventDefault();
        if(e.target.style.background==='green')
        {
           e.target.style.background = 'rgb(160,160,160)'
           document.getElementById("deposit").style.backgroundColor='green';
        }
        else
        {
          document.getElementById("deposit").style.backgroundColor='rgb(160,160,160)';
           e.target.style.background = 'green'
        }
    };
  return (
    <div>  <Appbar/>
    <Sidebar/>
    <div className='SalesContainer-0'>
    <h3>
        Billing Information
    </h3>
        <div className="DeliveryWidgetClient">
        <div className="newDeliveryItem">
            <label>Bill No</label>
            <input type="text" placeholder="Distributor" value={billNote.BillNo}/>
        </div>
        <div className="newDeliveryItem">
            <label>Order No</label>
            <input type="text" placeholder="Distributor" value={billNote.orderNo}/>
        </div>
        <div className="newDeliveryItem">
            <label>Distributor No</label>
            <input type="text" placeholder="Distributor" value={billNote.distributorNo}/>
        </div>
        <div className="newDeliveryItem">
            <label>Distributor Name</label>
            <input type="text" placeholder="Distributor" value={billNote.distributorName}/>
        </div>
        <div className="newDeliveryItem">
            <label>Contact No</label>
            <input type="text" placeholder="Distributor" value={billNote.contactNo}/>
        </div>
        </div>
        <Box component="form"  sx={{'& > :not(style)': { m: 1, width: '97%', mt:3 },}}noValidate autoComplete="off">
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 800 }} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell style={{ width: 160 }}>Image</TableCell>
            <TableCell align="left">P. Code</TableCell>
            <TableCell align="left">Qty</TableCell>
            <TableCell align="left">Total </TableCell>
            <TableCell align="left">Dep 1 </TableCell>
            <TableCell align="left">Dep 2 </TableCell>
            <TableCell align="left">Dep 3 </TableCell>
            <TableCell align="left">Tax</TableCell>
            <TableCell align="left">Need Pay $</TableCell>
            <TableCell align="left">Full Amount $</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell style={{ width: 100 }}align="left">Created At</TableCell>
            <TableCell style={{ width: 100 }}align="left">Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">{billNote.BillNo}
              </TableCell>
              <TableCell align="left">{billNote.productCode}</TableCell>
              <TableCell align="left">{billNote.quantity}</TableCell>
              <TableCell align="left">{billNote.total}</TableCell>
              <TableCell align="left">{billNote.deposit1}</TableCell>
              <TableCell align="left">{billNote.deposit2}</TableCell>
              <TableCell align="left">{billNote.deposit3}</TableCell>
              <TableCell align="left">{billNote.tax}</TableCell>
              <TableCell align="left">{billNote.balanceAmount}</TableCell>
              <TableCell align="left">{billNote.fullAmount}</TableCell>
              <TableCell align="left">{billNote.status}</TableCell>
              <TableCell align="left">{billNote.createdAt}</TableCell>
              <TableCell align="left">{billNote.updatedAt}</TableCell>
            </TableRow>
        </TableBody>
        </Table>
        </TableContainer>
        </Box>
        <h3>Payment Information</h3>
        <Box component="form" sx={{'& > :not(style)': { m: 1, width: '36ch', mt:1 }, }} noValidate autoComplete="off">
        <Box sx={{ display: 'flex', gap: 2 }}>
      <Button id="deposit" variant="contained" style={{ background: 'green', width:150}} onClick={handleClick_1} disabled>Deposit</Button>
      <Button id="fullpayment" variant="contained" style={{ background: 'rgb(160,160,160)' }} onClick={handleClick_2} disabled>Full Payment</Button>
    </Box>
    </Box>
    <div className="DeliveryWidgetClient">
    <div className="newDeliveryItem">
            <label>Total $</label>
            <input type="text" placeholder="" disabled value={billNote.total}/>
    </div>
    <div className="newDeliveryItem">
            <label>Balance Amount $</label>
            <input type="text" placeholder="" disabled value={billNote.balanceAmount}/>
    </div>
    </div>
    <div className="DeliveryWidgetClient">
    <div className="newDeliveryItem">
            <label>2nd Deposit $</label>
            <input type="number" placeholder="" onKeyDown={navigateDistributor_Enter}
            value={deposit2}
            onChange={(e)=>setdeposit2(e.target.value)}
            />
    </div>
    <div className="newDeliveryItem">
            <label>2nd Balance Amount $</label>
            <input type="text" placeholder="" disabled 
            value={balance}
            onChange={(e)=>setBalance(e.target.value)}/>
    </div>
   </div>
   <div className="DeliveryWidgetClient">
    <div className="newDeliveryItem">
            <label>3nd Deposit $</label>
            <input type="text" placeholder="" disabled/>
    </div>
    <div className="newDeliveryItem">
            <label>Balance Amount $</label>
            <input type="text" placeholder="" disabled />
    </div>
   </div>
        <Button variant="contained" onClick={handlePay} sx={{ background: 'rgb(64,64,64)' ,mb:3,mt:3}}>Generate Payment</Button>
        <div className="newDeliveryItem">
            <label>Amend By</label>
            <input type="text" placeholder="" value='Admin'/>
        </div>
        <br/>
    </div>
    </div>
  )
}

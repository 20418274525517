import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Payment.css'
export default function Payment() {
  return (
    <div><Appbar/>
    <Sidebar/>
    <div className='PaymentContainer'>
    Payment
    </div>
    </div>
  )
}

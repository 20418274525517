import React from 'react'
import Sidebar from './Sidebar/Sidebar'
import Appbar from './Appbar/Appbar'
export default function AdminProfile() {
  return (
    <div>
    <Appbar/>
    <Sidebar/>
</div>
  )
}

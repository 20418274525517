import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import {listCoupons} from '../../../../graphql/queries';
import {deleteCoupon} from '../../../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import Tooltip from '@mui/material/Tooltip';
import './Coupon.css'

export default function CouponListing() {
  const [coupon, setCoupon] = useState([]);
  useEffect(() => {
    fetchCoupon();
  }, []);
  const fetchCoupon = async ()=> {
    try{
      const CouponData = await API.graphql(graphqlOperation(listCoupons));
      const CouponList = CouponData.data.listCoupons.items;
      console.log('Distributor List',CouponList );
      setCoupon(CouponList);
    }
    catch(error)
    {
        console.log(error)
    }
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const remove = async (Oid) => {
    try{
      const todoDetails = {
        id: Oid,
      };
      await API.graphql({ query: deleteCoupon, variables: {input: todoDetails}});
      alert('Record deleted successfully')
      fetchCoupon();
    }
    catch(error)
    {
      console.log(error);
    }
  };
  return (
    <div><Appbar/>
    <Sidebar/>
    <div className='CouponContainer'>
    <h2>Coupon Listing</h2>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 220 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 200 }} align="left">Coupon Code</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">No of Coupon</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Start Date</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">End Date</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Discount Type</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Cash</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Create By</StyledTableCell>
            <StyledTableCell  style={{ width: 180 }} align="left">Amend By</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {coupon.map((Coupon, idx) => {
return (
            <>
             <StyledTableRow key={Coupon.productName}>
             <StyledTableCell align="left"> 
              <Tooltip title="View">{/*<Link to={"/couponItem/"+Coupon.id} className='link-menu'></Link>*/}
              <VisibilityIcon className='link-menu'/>
              </Tooltip>
             &nbsp;&nbsp;&nbsp;&nbsp;
             <Tooltip title="Delete">
             <DeleteIcon onClick={() =>remove(Coupon.id)} className='link-menu'/>
             </Tooltip>
             </StyledTableCell>
             <StyledTableCell align="left">{Coupon.couponCode}</StyledTableCell>
             <StyledTableCell align="left">{Coupon.couponCopy}</StyledTableCell>
             <StyledTableCell align="left">{Coupon.couponStartDate}</StyledTableCell>
             <StyledTableCell align="left">{Coupon.couponEndDate}</StyledTableCell>
             <StyledTableCell align="left">{Coupon.discountType}</StyledTableCell>
             <StyledTableCell align="left">{Coupon.cash}</StyledTableCell>
             <StyledTableCell align="left">{Coupon.createBy}</StyledTableCell>
             <StyledTableCell align="left">{Coupon.amendBy}</StyledTableCell>
             </StyledTableRow>
             </>
);
 })}
        </TableBody>
      </Table>
      </TableContainer>
    </div>
    </div>
  )
}

import React, { useState } from "react";
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Delivery.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Button} from '@mui/material';
import {createDeliveryNote} from '../../../../graphql/mutations';
import {API, graphqlOperation} from 'aws-amplify';
import {v4 as uuid} from 'uuid';
import Stack from '@mui/material/Stack';

export default function DeliveryNote() {
  const [addDeliveryNote,setDeliveryNote]=useState({})
  const [startDate, setStartDate] = useState(new Date());
  const handleClick= async()=>{
    try {
    const {deliveryNo,billNo,deliveryMethod,shippingAddress,courierServiceCompany,DeliveryBillNo,contactNo,createBy,amendBy}=addDeliveryNote;
    const addDeliveryInput = {
      id: uuid(),
      deliveryNo,
      billNo,
      deliveryMethod,
      shippingAddress,
      courierServiceCompany,
      DeliveryBillNo,
      contactNo,
      createBy,
      amendBy
    }
  await API.graphql(graphqlOperation(createDeliveryNote, { input: addDeliveryInput }));
  alert('Delivery Note Create Successfully');
  } catch (error) {
      console.log(error)
      alert('Waiting for Final Update');
  }
  };
  return (
    <div>
    <Appbar/>
    <Sidebar/>
    <div className="DistributorContainer">
    <h2>Delivery Note</h2>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
        <label>Delivery No</label>
        <input type="text" placeholder="" value={addDeliveryNote.deliveryNo} onChange={e =>setDeliveryNote({...addDeliveryNote, deliveryNo:e.target.value})}/>
          </div>
          <div className="newDeliveryItem1">
          <label>Bill No</label>
                                <select id="example" class="autocomplete1">
                                <option value="1">None</option>
                                </select>
          </div>
          
          <div className="newDeliveryItem">
                        <label>Order No</label>
                                <input type="text" disabled/>
                        </div>
                        <div className="newDeliveryItem1">
                        <label>Delivery Method</label>
                                <select id="example" class="autocomplete1">
                                <option value="1">None</option>
                                <option value="2">Self Pick-up</option>
                                <option value="3">Courier Service</option>
                                <option value="4">Security company</option>
                                </select>   
          </div>
          <div className="newDeliveryItem">
                        <label>Self Pick-Up Date/Time</label>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect dateFormat="Pp" className="datepicker"/>
                        </div> 
    </div>
    <h4 className="DeliveryWidgetClient-sub">Consignee/ Recipient</h4>
    <div className="DeliveryWidgetClient1">
                        <div className="newDeliveryItem1">
                                <label>Distributor No</label>
                                <input type="text" placeholder=""/>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Distributor Name</label>
                                <input type="text" placeholder="" disabled/>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Contact No</label>
                                <input type="text" placeholder="" disabled/>
                        </div>
                        </div> 
                        <div className="newDeliveryItem1">
                                <label>Shipping Address</label>
                                <input type="text" placeholder="" disabled/>
                        </div>
      <h4 className="DeliveryWidgetClient-sub">Courier Service</h4>
      <div className="DeliveryWidgetClient1">
      <div className="newDeliveryItem1">
                                <label>Courier Service Company</label>
                                <select id="example" class="autocomplete">
                                <option value="1">None</option>
                                <option value="2">DHL Courier Service</option>
                                <option value="3">BlueDart</option>
                                </select>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Delivery Bill No(Airway Bill no)</label>
                                <input type="text" placeholder=""/>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Contact No</label>
                                <input type="text" placeholder=""/>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Pick-Up Date</label>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect dateFormat="Pp" className="datepicker"/>
                        </div>
        </div>
        <br/>
        <div className="newDeliveryItem1">
                                <label>Create By</label>
                                <input type="text" placeholder="" disabled/>
                    </div>
                    <Stack spacing={2} direction="row" mb={3} mt={3}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Create</Button>
    </Stack>
    </div>
    
    </div>
  )
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAddproduct = /* GraphQL */ `
  query GetAddproduct($id: ID!) {
    getAddproduct(id: $id) {
      id
      productCode
      productName
      diamondShape
      shapeCutting
      diamondColor
      diamondCarat
      diamondClarity
      measurement
      ratio
      certificateNo
      msrp
      currency
      image
      createdAt
      updatedAt
    }
  }
`;
export const listAddproducts = /* GraphQL */ `
  query ListAddproducts(
    $filter: ModelAddproductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddproducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productCode
        productName
        diamondShape
        shapeCutting
        diamondColor
        diamondCarat
        diamondClarity
        measurement
        ratio
        certificateNo
        msrp
        currency
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAddDistributor = /* GraphQL */ `
  query GetAddDistributor($id: ID!) {
    getAddDistributor(id: $id) {
      id
      distributorID
      photoID
      firstname
      lastname
      nicNo
      address1
      address2
      state
      country
      zipCode
      occupation
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      username
      password
      confirmPassword
      phoneNo
      bankName
      accountNo
      shiftCode
      bankAddress
      bankcountry
      email
      sponsorId_UL1
      sponsorName_UL1
      contactNo_UL1
      sponsorId_UL2
      sponsorName_UL2
      contactNo_UL2
      createdAt
      updatedAt
    }
  }
`;
export const listAddDistributors = /* GraphQL */ `
  query ListAddDistributors(
    $filter: ModelAddDistributorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        distributorID
        photoID
        firstname
        lastname
        nicNo
        address1
        address2
        state
        country
        zipCode
        occupation
        shippingAddress1
        shippingAddress2
        shippingState
        shippingCountry
        shippingZipCode
        BillingAddress1
        BillingAddress2
        BillingState
        BillingCountry
        BillingZipCode
        username
        password
        confirmPassword
        phoneNo
        bankName
        accountNo
        shiftCode
        bankAddress
        bankcountry
        email
        sponsorId_UL1
        sponsorName_UL1
        contactNo_UL1
        sponsorId_UL2
        sponsorName_UL2
        contactNo_UL2
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAddAdmin = /* GraphQL */ `
  query GetAddAdmin($id: ID!) {
    getAddAdmin(id: $id) {
      id
      firstname
      lastname
      username
      password
      confirmPassword
      phoneNo
      product
      distributor
      eCommerce
      marketing
      delivery
      asm
      createdAt
      updatedAt
    }
  }
`;
export const listAddAdmins = /* GraphQL */ `
  query ListAddAdmins(
    $filter: ModelAddAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        username
        password
        confirmPassword
        phoneNo
        product
        distributor
        eCommerce
        marketing
        delivery
        asm
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderCart = /* GraphQL */ `
  query GetOrderCart($id: ID!) {
    getOrderCart(id: $id) {
      id
      orderNo
      distributorNo
      distributorName
      contactNo
      BillingAddress1
      BillingAddress2
      BillingState
      BillingCountry
      BillingZipCode
      shippingAddress1
      shippingAddress2
      shippingState
      shippingCountry
      shippingZipCode
      productID
      productCode
      productName
      quantity
      amount
      status
      createdAt
      updatedAt
    }
  }
`;
export const listOrderCarts = /* GraphQL */ `
  query ListOrderCarts(
    $filter: ModelOrderCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderNo
        distributorNo
        distributorName
        contactNo
        BillingAddress1
        BillingAddress2
        BillingState
        BillingCountry
        BillingZipCode
        shippingAddress1
        shippingAddress2
        shippingState
        shippingCountry
        shippingZipCode
        productID
        productCode
        productName
        quantity
        amount
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPurchaseOrder = /* GraphQL */ `
  query GetPurchaseOrder($id: ID!) {
    getPurchaseOrder(id: $id) {
      id
      orderNo
      purchaseOrder
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      balanceAmount
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const listPurchaseOrders = /* GraphQL */ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderNo
        purchaseOrder
        distributorNo
        distributorName
        contactNo
        productCode
        quantity
        balanceAmount
        createBy
        amendBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeliveryNote = /* GraphQL */ `
  query GetDeliveryNote($id: ID!) {
    getDeliveryNote(id: $id) {
      id
      deliveryNo
      billNo
      deliveryMethod
      shippingAddress
      courierServiceCompany
      DeliveryBillNo
      contactNo
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const listDeliveryNotes = /* GraphQL */ `
  query ListDeliveryNotes(
    $filter: ModelDeliveryNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deliveryNo
        billNo
        deliveryMethod
        shippingAddress
        courierServiceCompany
        DeliveryBillNo
        contactNo
        createBy
        amendBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetupRules = /* GraphQL */ `
  query GetSetupRules($id: ID!) {
    getSetupRules(id: $id) {
      id
      distributorML1
      distributorML2
      distributorML3
      dealerDL1
      dealerDL2
      dealerDL3
      GlobalDeposit
      OrderDuration
      TAX
      createdAt
      updatedAt
    }
  }
`;
export const listSetupRules = /* GraphQL */ `
  query ListSetupRules(
    $filter: ModelSetupRulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSetupRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        distributorML1
        distributorML2
        distributorML3
        dealerDL1
        dealerDL2
        dealerDL3
        GlobalDeposit
        OrderDuration
        TAX
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBillNote = /* GraphQL */ `
  query GetBillNote($id: ID!) {
    getBillNote(id: $id) {
      id
      orderNo
      BillNo
      distributorNo
      distributorName
      contactNo
      productCode
      quantity
      total
      tax
      deposit1
      deposit2
      deposit3
      balanceAmount
      fullAmount
      status
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const listBillNotes = /* GraphQL */ `
  query ListBillNotes(
    $filter: ModelBillNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderNo
        BillNo
        distributorNo
        distributorName
        contactNo
        productCode
        quantity
        total
        tax
        deposit1
        deposit2
        deposit3
        balanceAmount
        fullAmount
        status
        createBy
        amendBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      couponCode
      couponCopy
      couponStartDate
      couponEndDate
      discountType
      cash
      createBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        couponCode
        couponCopy
        couponStartDate
        couponEndDate
        discountType
        cash
        createBy
        amendBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBonus = /* GraphQL */ `
  query GetBonus($id: ID!) {
    getBonus(id: $id) {
      id
      BillNo
      billAmount
      distributorName
      relation
      percentage
      bonusAmount
      BillCreationDate
      createdAt
      updatedAt
    }
  }
`;
export const listBonuses = /* GraphQL */ `
  query ListBonuses(
    $filter: ModelBonusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBonuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BillNo
        billAmount
        distributorName
        relation
        percentage
        bonusAmount
        BillCreationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAsm = /* GraphQL */ `
  query GetAsm($id: ID!) {
    getAsm(id: $id) {
      id
      asmNo
      distributorNo
      distributorName
      orderNo
      contactNo
      certificateNo
      productCode
      productName
      serviceRequest
      serviceRemark
      billingDate
      estReadyDate
      deliveryMethod
      courierServiceCompany
      courierContactNo
      selfPickUpDate
      createdBy
      amendBy
      createdAt
      updatedAt
    }
  }
`;
export const listAsms = /* GraphQL */ `
  query ListAsms(
    $filter: ModelAsmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        asmNo
        distributorNo
        distributorName
        orderNo
        contactNo
        certificateNo
        productCode
        productName
        serviceRequest
        serviceRemark
        billingDate
        estReadyDate
        deliveryMethod
        courierServiceCompany
        courierContactNo
        selfPickUpDate
        createdBy
        amendBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCartData = /* GraphQL */ `
  query GetCartData($id: ID!) {
    getCartData(id: $id) {
      id
      Image
      distributorID
      productID
      productName
      carat
      qty
      msrp
      currency
      createdAt
      updatedAt
    }
  }
`;
export const listCartData = /* GraphQL */ `
  query ListCartData(
    $filter: ModelCartDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Image
        distributorID
        productID
        productName
        carat
        qty
        msrp
        currency
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

import React, {useState, useEffect} from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import {listAddDistributors} from '../../../../graphql/queries';
import {deleteAddDistributor} from '../../../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from "react-router-dom"
import Tooltip from '@mui/material/Tooltip';

export default function DistributorListing() {
    
    const [distributor, setDistributor] = useState([]);
    useEffect(() => {
      fetchDistributor();
    }, []);
    const fetchDistributor = async ()=> {
      try{
        const DistributorData = await API.graphql(graphqlOperation(listAddDistributors));
        const DistributorList = DistributorData.data.listAddDistributors.items;
        console.log('Distributor List',DistributorList );
        setDistributor(DistributorList);
      }catch(error)
      {
        console.log('error on fetching Distributor', error);
      }
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
      },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
    const remove = async (Oid) => {
      try{
        const todoDetails = {
          id: Oid,
        };
        await API.graphql({ query: deleteAddDistributor, variables: {input: todoDetails}});
        alert('Record deleted successfully')
        fetchDistributor();
      }
      catch(error)
      {
        console.log(error);
      }
    };
  return (
    <div>
        <Appbar/>
        <Sidebar/>
        <div className="DistributorContainer">
        <div>
    <h2>Distributor List</h2>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 160 }} align="left">Action</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Distributor ID</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">User Name</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Phone No</StyledTableCell>
            <StyledTableCell  style={{ width: 100 }} align="left">Email</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Sponsor's ID</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">S.Name</StyledTableCell>
            <StyledTableCell  style={{ width: 180 }} align="left">Contact No</StyledTableCell>
            <StyledTableCell  style={{ width: 160 }} align="left">Register At</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {distributor.map((Distributor, idx) => {
return (
            <>
             <StyledTableRow key={Distributor.productName}>
             <StyledTableCell align="left"> 
             <Tooltip title="View"><Link to={"/distributorItem/"+Distributor.id} className='link-menu'>
              <VisibilityIcon/>
              </Link>
              </Tooltip>
             &nbsp;&nbsp;&nbsp;&nbsp;
             <Tooltip title="Delete">
             <DeleteIcon onClick={() =>remove(Distributor.id)} className='link-menu'/>
             </Tooltip>
             </StyledTableCell>
             <StyledTableCell align="left">{Distributor.id}</StyledTableCell>
             <StyledTableCell align="left">{Distributor.username}</StyledTableCell>
             <StyledTableCell align="left">{Distributor.phoneNo}</StyledTableCell>
             <StyledTableCell align="left">{Distributor.email}</StyledTableCell>
             <StyledTableCell align="left">{Distributor.sponsorId_UL2}</StyledTableCell>
             <StyledTableCell align="left">{Distributor.sponsorName_UL2}</StyledTableCell>
             <StyledTableCell align="left">{Distributor.contactNo_UL2}</StyledTableCell>
             <StyledTableCell align="left">{Distributor.createdAt}</StyledTableCell>
             </StyledTableRow>
             </>
);
 })}
        </TableBody>
      </Table>
      </TableContainer>
</div>
        </div>
    </div>
  )
}

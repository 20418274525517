import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Sales.css'

export default function InvoiceListing() {
  return (
    <div>
    <Appbar/>
    <Sidebar/>
    <div className="DistributorContainer">
        Invoice Listing
    </div>
    </div>
  )
}

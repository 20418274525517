import React from 'react'
import SideNav,{Toggle, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import "./Sidebar.css"
import {useNavigate} from 'react-router-dom';
export default function Sidebar(props) {
    const idval = props.item;
    const profile = 'profile/'+idval
    const DistributorDashboard = 'DistributorDashboard/'+idval
    const YourOrder = 'YourOrder/'+idval
    const MybillingInfo = 'MybillingInfo/'+idval
    const DGroup = 'DGroup/'+idval
    const DBonus = 'DBonus/'+idval
    const navigate = useNavigate();
  return (
   <SideNav
    onSelect={(selected) => {
        // Add your code here
        console.log(selected);
        navigate('/'+selected)
    }}
    className="Mysidebar"
> 
<SideNav.Toggle />
<SideNav.Nav defaultSelected="home">
        <NavItem eventKey={DistributorDashboard}>
            <NavIcon>
            <i className="fa fa-fw fa-home" style={{ fontSize: '1.5em' }} />
            </NavIcon>
            <NavText>
                Home
            </NavText>
        </NavItem>
        <NavItem eventKey={profile}>
            <NavIcon>
            <i class="fa-solid fa-address-card" style={{ fontSize: '1.5em' }}></i>
            </NavIcon>
            <NavText>
                Profile
            </NavText>
        </NavItem>
        <NavItem eventKey="yourOrder">
            <NavIcon>
            <i class="fa-solid fa-cart-shopping" style={{ fontSize: '1.5em' }}></i>
            </NavIcon>
            <NavText>
                Order Details
            </NavText>
            <NavItem eventKey={YourOrder}>
            <NavText>
                Order Info
            </NavText>
            </NavItem>
            <NavItem eventKey={MybillingInfo}>
            <NavText>
                Billing Info
            </NavText>
            </NavItem>
        </NavItem>
        <NavItem eventKey="">
            <NavIcon>
            <i class="fa-solid fa-network-wired" style={{ fontSize: '1.5em' }}></i>
            </NavIcon>
            <NavText>
                Network
            </NavText>
            <NavItem eventKey={DGroup}>
            <NavText>
                Group
            </NavText>
            </NavItem>
            <NavItem eventKey={DBonus}>
            <NavText>
                Bonus
            </NavText>
            </NavItem>
        </NavItem>
    </SideNav.Nav>
    </SideNav>
  )
}

import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { API, graphqlOperation,Storage } from 'aws-amplify';
import { getOrderCart,getAddproduct,getSetupRules,getAddDistributor} from '../../../graphql/queries';
import  Appbar  from '../../Appbar/Appbar'
import Sidebar from '../Sidebar/Sidebar'
import './Sales.css'
import Box from '@mui/material/Box';

import TextareaAutosize from '@mui/material/TextareaAutosize';

export default function YourCartItems() {
  const {id}= useParams();
  const {type}= useParams();
  const [distributor,setDistributor]=useState([]);
  const [product, setProducts] = useState([]);
  const [productImageURL, setproductImageURL] = useState('')
  const [orderCart, setOrderCart] = useState([]);
  const [setup,setSetup] = useState([]);
  var TaxVal = 0.0;
var totalAmt= 0.0;
var depositAmt= 0.0;
var balAmt= 0.0;
useEffect(() => {
  fetchProduct();
}, []);
const fetchProduct = async () => {
    try {
        const OrderData = await API.graphql(graphqlOperation(getOrderCart, { id: id }));
        const OrderList = OrderData.data.getOrderCart;
        console.log(OrderList);
        setOrderCart(OrderList);

        const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: OrderList.distributorNo }));
        const DistributorList = DistributorData.data.getAddDistributor;
        console.log(DistributorList);
        setDistributor(DistributorList);

        const productData = await API.graphql(graphqlOperation(getAddproduct, { id: OrderList.productID }));
            const productList = productData.data.getAddproduct;
            console.log(productList);
            setProducts(productList);
            const productFilePath = productList.image
            try
            {
                const fileAccessURL = await Storage.get(productFilePath, {expires:60})
                console.log('access url', fileAccessURL);
                setproductImageURL(fileAccessURL)
            }
            catch(error){
                console.error('error accessing the file from s3', error)
                setproductImageURL('');
            }
    }
    catch (error){
      console.log('error on fetching Order Cart', error);
    }
    try{
      const SetupData = await API.graphql(graphqlOperation(getSetupRules,{id:'001b52d5-2d3d-4ed1-8a09-b91791ee96e0'}))
      const SetupList = SetupData.data.getSetupRules;
      console.log(SetupList);
      setSetup(SetupList);
    }
    catch (error){
      console.log('error on fetching Setup Rules', error);
    }
  };
  var DisDistributor = (parseFloat(20)/100) * parseFloat(orderCart.amount)
  totalAmt = parseFloat(orderCart.amount) - DisDistributor
  depositAmt = (parseFloat(totalAmt))*(parseFloat(setup.GlobalDeposit)/100)
  balAmt = totalAmt - depositAmt
  TaxVal = (parseFloat(setup.TAX)/100) * parseFloat(totalAmt)
  var balAmtWithTax = balAmt + TaxVal;
  const BillingAddress = orderCart.BillingAddress1 +", "+ orderCart.BillingAddress2+", "+orderCart.BillingState+", "+orderCart.BillingCountry +", "+orderCart.BillingZipCode
    const ShippingAddress = orderCart.shippingAddress1 +", "+ orderCart.shippingAddress2+", "+orderCart.shippingState+", "+orderCart.shippingCountry +", "+orderCart.shippingZipCode
  return (
    <div>
        <Appbar item={type} name={distributor.firstname}/>
    <Sidebar item={type}/>
    <div className='SalesContainer'>
    <div className="OrderWidgetClient1">
    <div className="newOrderItem1">
        <h3>Order Information</h3>
        <Box component="form" sx={{'& > :not(style)': { m: 1, mt:1 },}}noValidate autoComplete="off">
        <h4 className="paymentOption">Your Order Items</h4>
        Order No: {orderCart.orderNo}
        <br/>
        <img className="ProductImg-Shop" src={productImageURL} alt="" />
        <br/>
        <div className='fontfamily'>
        <table>
  <tr>
    <td>Product Name</td>
    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.productName}</td>
  </tr>
  <tr>
  <td>Quantity</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{orderCart.quantity}</td>
  </tr>
  <tr>
  <td>Shape</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondShape}</td>
  </tr>
  <tr>
  <td>Cutting</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.shapeCutting}</td>
  </tr>
  <tr>
  <td>Clarity</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondClarity}</td>
  </tr>
  <tr>
  <td>Carat</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondCarat}</td>
  </tr>
  <tr>
  <td>Color</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{product.diamondColor}</td>
  </tr>
  <tr>
  <td>MSRP</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{orderCart.amount}</td>
  </tr>
</table>
        </div>
        </Box>
       </div>
       <div className="newOrderItem1">
        <h3>Distributor Information</h3>
        <Box component="form" sx={{'& > :not(style)': { m: 1, mt:1 },}}noValidate autoComplete="off">
        Distributor Name: {orderCart.distributorName}
        <br/>
        Contact No: {orderCart.contactNo}
        <h5>Billing Address</h5>
        <TextareaAutosize aria-label="minimum height" minRows={3} value={BillingAddress} style={{ width: 400, fontSize:17,borderRadius:5}}/>
        <h5>Shipping Address</h5>
        <TextareaAutosize aria-label="minimum height" minRows={3} value={ShippingAddress} style={{ width: 400,fontSize:17,borderRadius:5}}/>
        <h4>Sponsor's Information</h4>
        <table>
        <tr>
        <td>Sponsor Name:</td>
        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{distributor.sponsorName}</td>
        </tr>
        <tr>
        <td>Contact No: </td>
        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{distributor.phoneNo}</td>
        </tr>
        </table>
        </Box>
    </div>
    <div className="newOrderItem1">
      <h3>Payment Information</h3>
      <Box component="form" sx={{'& > :not(style)': { m: 1, width: '36ch', mt:1 }, }} noValidate autoComplete="off">
      <table>
  <tr>
  <td>MSRP</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {orderCart.amount}</td>
  </tr>
  <tr>
  <td>Distributor 20%</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {DisDistributor}</td>
  </tr>
  <tr>
  <td><hr/></td>
  <td><hr/></td>
  </tr>
  <tr>
  <td>Total</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {totalAmt}</td>
  </tr>
  <tr>
  <td><hr/></td>
  <td><hr/></td>
  </tr>
  <tr>
  <td>Deposit {setup.GlobalDeposit}%</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {depositAmt}</td>
  </tr>
  </table>
  <table>
  <tr>
  <td><hr/></td>
  <td><hr/></td>
  </tr>
  <tr>
  <td>Balance Amount</td>
  <td>$ {balAmt}</td>
  </tr>
  <tr>
  <td>Tax {setup.TAX}% ({totalAmt})</td>
  <td>$ {TaxVal}</td>
  </tr>
  <tr>
  <td><hr/></td>
  <td><hr/></td>
  </tr>
  <tr>
  <td>Final Amount</td>
  <td>$ {balAmtWithTax}</td>
  </tr>
</table>
<h3>Transcation Information</h3>
<table>
  <tr>
  <td>Status</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{orderCart.status}</td>
  </tr>
  <tr>
  <td>Created At</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{orderCart.createdAt}</td>
  </tr>
  <tr>
  <td>Updated At</td>
  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{orderCart.updatedAt}</td>
  </tr>
</table>
</Box>
</div>
</div>
</div>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Appbar from '../../Appbar/Appbar'
import Sidebar from '../../Sidebar/Sidebar'
import { getAddproduct} from '../../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import '../../../Product/Products.css'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function ProductAmend() {
  const [product, setProducts] = useState([]);
    const {id}= useParams();
    useEffect(() => {
      fetchProduct();
    }, []);
    const fetchProduct = async () => {
        try {
            const productData = await API.graphql(graphqlOperation(getAddproduct, { id: id }));
            const productList = productData.data.getAddproduct;
            console.log(productList);
            setProducts(productList);
        }
        catch (error){
          console.log('error on fetching product', error);
        }
      };
  return (
    <div>
        <Appbar/>
        <Sidebar/>
      <br/>
      <br/>
      <br/>
      <div className="ShoppingContainer"> 
      <img className="ProductImg" src={product.image} alt="" />
      <br/>
      <h4>{product.productName}</h4>
      Shape: {product.diamondShape}
      <br/>
      Carat: {product.diamondCarat}
      <br/>
      MSRP: {product.msrp} {product.currency}
      <br/>
      <br/>
      <h2>Diamond Details</h2>
        <div className="DiamondDetails">
        
      <Table sx={{width:300}} aria-label="caption table">
        <TableHead>
        <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell align="left">{product.productName}</TableCell>
          </TableRow>
        <TableRow>
            <TableCell>Product Code</TableCell>
            <TableCell align="left">{product.productCode}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Shape</TableCell>
            <TableCell align="left">{product.diamondShape}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cut</TableCell>
            <TableCell align="left">{product.shapeCutting}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Color</TableCell>
            <TableCell align="left">{product.diamondColor}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Clarity</TableCell>
            <TableCell align="left">{product.diamondClarity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Carat Weight</TableCell>
            <TableCell align="left">{product.diamondCarat}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Certificate</TableCell>
            <TableCell align="left">{product.certificateNo}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Measurements</TableCell>
            <TableCell align="left">{product.measurement}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Length/Width Ratio</TableCell>
            <TableCell align="left">{product.ratio}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
        </div>
      </div>
    </div>
  )
}

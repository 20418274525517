import React, { useState } from "react";
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './AfterSales.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Stack from '@mui/material/Stack';
import {Button} from '@mui/material';

export default function AfterSalesService() {
  const [startDate, setStartDate] = useState(new Date());
  const handleClick= async()=>{
    try {
      alert('Waiting Payment API to complete')
    }
    catch(error)
    {
        console.log(error)
    }
  };
  return (
    <div>
    <Appbar/>
    <Sidebar/>
    <div className='AfterContainer'>
    <h2>After Sales Maintenance</h2>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
          <label>Atfer Sales Maintenance No</label>
          <input type="text" placeholder=""/>
        </div>
        </div> 
        <h4 className="DeliveryWidgetClient-sub">Distributor Info</h4>
            <div className="DeliveryWidgetClient1">
            <div className="newDeliveryItem1">
                                <label>Distributor No</label>
                                <input type="text" placeholder=""/>
                                </div>
                                <div className="newDeliveryItem1">
                                <label>Distributor Name</label>
                                <input type="text" placeholder=""/>
                                </div>
                                <div className="newDeliveryItem1">
                                <label>Bill No</label>
                                <input type="text" placeholder=""/>
                                </div>
                        <div className="newDeliveryItem1">
                        <label>Contact No</label>
                                <input type="text" placeholder=""/>
                                </div>   
                                <div className="newDeliveryItem1">
                                <label>Certificate No</label>
                                <input type="text" placeholder=""/>
                         </div>
                        </div>  
              <h4 className="DeliveryWidgetClient-sub">Product Details</h4>
              <div className="DeliveryWidgetClient1">
                          <div className="newDeliveryItem1">
                                <label>Product Code</label>
                                <input type="text" placeholder=""/>
                          </div>
                                <div className="newDeliveryItem1">
                                <label>Product Name</label>
                                <input type="text" placeholder=""/>
                                </div>

                                <div className="newDeliveryItem1">
                                <label>Service Request</label>
                                <select id="example" class="autocomplete">
                                <option value="1">Select Type of Service</option>
                                <option value="2">Cleaning</option>
                                </select>
                                </div>
                        <div className="newDeliveryItem1">
                        <label>Service Remark</label>
                        <textarea className="multiline-text"/>
                        </div>
                        <div className="newDeliveryItem1">
                        <label>Purchase Date</label>
                        <input type="text" placeholder=""/>
                        </div>
                        <div className="newDeliveryItem1">
                        <label className="namedefine1">Estimate Ready Date</label>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect dateFormat="Pp" className="datepicker"/>
                        </div>
                        </div>
              <h4 className="DeliveryWidgetClient-sub">Courier Service</h4>
              <div className="DeliveryWidgetClient1">
              <div className="newDeliveryItem1">
                                <label>Delivery Method</label>
                                <select id="example" class="autocomplete">
                                <option value="1">None</option>
                                <option value="2">Self Pick-Up</option>
                                <option value="3">Courier Service</option>
                                <option value="4">Security Company</option>
                                </select>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Courier Service Company</label>
                                <select id="example" class="autocomplete">
                                <option value="1">None</option>
                                <option value="2">DHL Courier Service</option>
                                <option value="3">BlueDart</option>
                                </select>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Contact No</label>
                                <input type="text" placeholder=""/>
                        </div>
                        </div>
                        <div className="DeliveryWidgetClient1">
                        <div className="newDeliveryItem1">
                                <label>Self Pick-Up Date</label>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect dateFormat="Pp" className="datepicker"/>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Shipping Address 1</label>
                                <input type="text" placeholder=""/>
                        </div>
                        <div className="newDeliveryItem1">
                                <label>Shipping Address 2</label>
                                <input type="text" placeholder=""/>
                        </div>

      </div>
      <br/>
      <br/>
                    <div className="newDeliveryItem1">
                                <label>Create By</label>
                                <input type="text" placeholder="" disabled/>
                    </div>
                    <Stack spacing={2} direction="row" mb={3} mt={3}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Create</Button>
    </Stack>
    </div>
    </div>
  )
}

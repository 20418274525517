import * as React from 'react';
import Typography from '../Typography';
import ProductHeroLayout from './ProductHeroLayout';

import {useNavigate} from "react-router-dom";
const backgroundImage =
  'https://static.wixstatic.com/media/5dee0c_ffa00a461a7c4ea5930ed5d7784b33fb~mv2.jpg/v1/fill/w_2156,h_1066,al_c,q_90,enc_auto/5dee0c_ffa00a461a7c4ea5930ed5d7784b33fb~mv2.jpg';

export default function Content() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
        <br/>
        <br/>
      <Typography color="inherit" align="center" variant="h3" marked="center">
       DD LAB
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
       Data Here<br/>
      </Typography>
    </ProductHeroLayout>
  );
}

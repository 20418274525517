import  Appbar  from '../Appbar/Appbar'
import Sidebar from './Sidebar/Sidebar'
import FeatureBox from './DistributorPages/HomePage/FeaturedInfo'
import "./DistributorData.css"
import React, { useState, useEffect } from 'react';
import { listAddproducts,getAddDistributor} from '../../graphql/queries';
import { API, graphqlOperation,Storage } from 'aws-amplify';
import { useParams } from 'react-router-dom'

export default function DistributorDashboard() {
  const{id} = useParams();
  useEffect(() => {
    fetchDistributor();
  }, []);
  const [addDistributor,setAddDistributor]=useState({})
  const fetchDistributor = async () => {
    try {
    const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
    const DistribitorList = DistributorData.data.getAddDistributor;
    console.log(DistribitorList);
    setAddDistributor(DistribitorList);
    } catch (error) {
        console.log(error)
    }
  };
  return (
    <div>
        <Appbar item={id} name={addDistributor.firstname}/>
        <Sidebar item={id}/>
        <div className="Container">
        <FeatureBox/>
        </div>
    </div>
  )
}

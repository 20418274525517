import React, { useState, useEffect } from 'react';
import "../DistributorData.css"
import  Appbar  from '../../Appbar/Appbar'
import Box from '@mui/material/Box';
import ContactsIcon from '@mui/icons-material/Contacts';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Typography from '@mui/material/Typography';
import Sidebar from '../Sidebar/Sidebar'
import { API, graphqlOperation,Storage } from 'aws-amplify';
import { getAddDistributor} from '../../../graphql/queries';
import {v4 as uuid} from 'uuid';
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
} from "@material-ui/icons";
import { useParams } from 'react-router-dom'
import { Button} from '@mui/material';
import Stack from '@mui/material/Stack';
import {updateAddDistributor} from '../../../graphql/mutations';
export default function DistributorProfile() {
  const{id} = useParams();
  const [addDistributor,setAddDistributor]=useState({})
  const [imageData,setImageData]=useState()
    const [state, setState]=useState({
      profileImg:'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
  })
  const {profileImg} = state
const  fileSelectedHandler= (event) =>{
      console.log(event.target.files[0])
      const reader = new FileReader();
      reader.onload = () =>{
          if(reader.readyState === 2)
          {
            setDistributorImageURL({DistributorImageURL: reader.result})
      }
  }
  reader.readAsDataURL(event.target.files[0])
};
useEffect(() => {
    fetchDistributor();
  }, []);
  const [DistributorImageURL, setDistributorImageURL] = useState('')
  const fetchDistributor = async () => {
    try {
    const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: id }));
    const DistribitorList = DistributorData.data.getAddDistributor;
    console.log(DistribitorList);
    setAddDistributor(DistribitorList);
    const distributorFilePath = DistribitorList.photoID
          try
          {
              const fileAccessURL = await Storage.get(distributorFilePath, {expires:60})
              console.log('access url', fileAccessURL);
              setDistributorImageURL(fileAccessURL)
          }
          catch(error){
              console.error('error accessing the file from s3', error)
              setDistributorImageURL('');
          }
    } catch (error) {
        console.log(error)
    }
  };
  const [updateDistributor,setUpdateDistributor]=useState({})
  const handleClick= async()=>{
    try {
        const {key} = await Storage.put(`${uuid()}.jpg`,imageData, {contentType:'image/jpg'});
    const {firstname,lastname,nicNo,address1,address2,state,country,zipCode,occupation,
        shippingAddress1,shippingAddress2,shippingState,shippingCountry,shippingZipCode,BillingAddress1,
        BillingAddress2,BillingState,BillingCountry,
        BillingZipCode,username,phoneNo,bankName,accountNo,shiftCode,
      bankAddress,bankcountry,sponsorId_UL1,
      sponsorName_UL1,
      contactNo_UL1,
      sponsorId_UL2,
      sponsorName_UL2,
      contactNo_UL2}=updateDistributor;
      const createUpdateDistributorInput = {
        id: id,
        firstname,
        lastname,
        nicNo,
        address1,
        address2,
        state,
        country,
        zipCode,
        occupation,
        shippingAddress1,
        shippingAddress2,
        shippingState,
        shippingCountry,
        shippingZipCode,
        BillingAddress1,
        BillingAddress2,
        BillingState,
        BillingCountry,
        BillingZipCode,
        username,
        phoneNo,
        bankName,
        accountNo,
        shiftCode,
        bankAddress,
        bankcountry,
        sponsorId_UL1,
        sponsorName_UL1,
        contactNo_UL1,
        sponsorId_UL2,
        sponsorName_UL2,
        contactNo_UL2,
        photoID: key,
      }
      await API.graphql(graphqlOperation(updateAddDistributor, {input: createUpdateDistributorInput}));
   alert('Record Updated Successfully')
   fetchDistributor();
  } catch (error) {
      console.log(error)
  }
  };
  return (
    <>
    <div>
      <Appbar item={id} name={addDistributor.firstname}/>
      <Sidebar item={id}/>
    </div>
    <div className="SalesContainer-1">
        <img className="ProductImg" src={DistributorImageURL} id="img"/>
              <br/>
              <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="contained" component="label" onChange={fileSelectedHandler} style={{ background: 'rgb(64,64,64)' }}>
                Upload
                <input hidden accept="image/png, image/jpeg" multiple type="file"  onChange={e=> setDistributorImageURL(e.target.files[0])}/>
             </Button>
              </Stack>
        <div className="distributorItem0">
              <span className="userShowUsername-Edit">Edit Profile</span>
              <h4 className="DistributorNo">Distributor No : </h4>
      <h5 className="miniHeader">Personal Details</h5>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>First Name</label>
            <input type="text" placeholder="First Name" defaultValue={addDistributor.firstname}
            onChange={e =>setUpdateDistributor({...updateDistributor, firstname:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" defaultValue={addDistributor.lastname}
            onChange={e =>setUpdateDistributor({...updateDistributor, lastname:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>NIC No</label>
            <input type="text" placeholder="NIC No" defaultValue={addDistributor.nicNo}
            onChange={e =>setUpdateDistributor({...updateDistributor, nicNo:e.target.value})}/>
        </div>
      </div>
        <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Address 1</label>
            <input type="text" placeholder="Address 1" defaultValue={addDistributor.address1}
            onChange={e =>setUpdateDistributor({...updateDistributor, address1:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Address 2</label>
            <input type="text" placeholder="Address 2" defaultValue={addDistributor.address2}
            onChange={e =>setUpdateDistributor({...updateDistributor, address2:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>State</label>
            <input type="text" placeholder="State" defaultValue={addDistributor.state}
            onChange={e =>setUpdateDistributor({...updateDistributor, state:e.target.value})}/>
        </div>
      </div>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Country</label>
            <input type="text" placeholder="Country" defaultValue={addDistributor.country}
            onChange={e =>setUpdateDistributor({...updateDistributor, country:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Zip Code</label>
            <input type="text" placeholder="Zip Code" defaultValue={addDistributor.zipCode}
            onChange={e =>setUpdateDistributor({...updateDistributor, zipCode:e.target.value})}/>
        </div>
        </div>
        <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Occupation</label>
            <input type="text" placeholder="Occupation" defaultValue={addDistributor.occupation}
            onChange={e =>setUpdateDistributor({...updateDistributor, occupation:e.target.value})}/>
        </div>
        </div>
        <h5 className="miniHeader">Billing Details</h5>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Address 1</label>
            <input type="text" placeholder="Address 1" defaultValue={addDistributor.BillingAddress1}
            onChange={e =>setUpdateDistributor({...updateDistributor, BillingAddress1:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Address 2</label>
            <input type="text" placeholder="Address 2" defaultValue={addDistributor.BillingAddress2}
            onChange={e =>setUpdateDistributor({...updateDistributor, BillingAddress2:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>State</label>
            <input type="text" placeholder="State" defaultValue={addDistributor.BillingState}
            onChange={e =>setUpdateDistributor({...updateDistributor, BillingState:e.target.value})}/>
        </div>
      </div>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Country</label>
            <input type="text" placeholder="Country" defaultValue={addDistributor.BillingCountry}
            onChange={e =>setUpdateDistributor({...updateDistributor, BillingCountry:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Zip Code</label>
            <input type="text" placeholder="Zip Code" defaultValue={addDistributor.BillingZipCode}
            onChange={e =>setUpdateDistributor({...updateDistributor, BillingZipCode:e.target.value})}/>
        </div>
      </div>
      <h5 className="miniHeader">Shipping Details</h5>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Address 1</label>
            <input type="text" placeholder="Address 1" defaultValue={addDistributor.shippingAddress1}
            onChange={e =>setUpdateDistributor({...updateDistributor, shippingAddress1:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Address 2</label>
            <input type="text" placeholder="Address 2" defaultValue={addDistributor.shippingAddress2}
            onChange={e =>setUpdateDistributor({...updateDistributor, shippingAddress2:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>State</label>
            <input type="text" placeholder="State" defaultValue={addDistributor.shippingState}
            onChange={e =>setUpdateDistributor({...updateDistributor, shippingState:e.target.value})}/>
        </div>
      </div>
      <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Country</label>
            <input type="text" placeholder="Country" defaultValue={addDistributor.shippingCountry}
            onChange={e =>setUpdateDistributor({...updateDistributor, shippingCountry:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Zip Code</label>
            <input type="text" placeholder="Zip Code" defaultValue={addDistributor.shippingZipCode}
            onChange={e =>setUpdateDistributor({...updateDistributor, shippingZipCode:e.target.value})}/>
        </div>
      </div>

        <h5 className="miniHeader">Login & Verification Details</h5>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>User Name</label>
            <input type="text" placeholder="User Name" defaultValue={addDistributor.username}
            onChange={e =>setUpdateDistributor({...updateDistributor, username:e.target.value})}/>
        </div>
    </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Phone Number</label>
            <input type="text" placeholder="Phone Number" defaultValue={addDistributor.phoneNo}
            onChange={e =>setUpdateDistributor({...updateDistributor, phoneNo:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>E-Mail</label>
            <input type="text" placeholder="E-Mail" defaultValue={addDistributor.email}
            onChange={e =>setUpdateDistributor({...updateDistributor, email:e.target.value})}/>
        </div>
    </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Bank Name</label>
            <input type="text" placeholder="Bank Name" defaultValue={addDistributor.bankName}
            onChange={e =>setUpdateDistributor({...updateDistributor, bankName:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Account No</label>
            <input type="text" placeholder="Account No" defaultValue={addDistributor.accountNo}
            onChange={e =>setUpdateDistributor({...updateDistributor, accountNo:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Shift Code</label>
            <input type="text" placeholder="Shift Code" defaultValue={addDistributor.shiftCode}
            onChange={e =>setUpdateDistributor({...updateDistributor, shiftCode:e.target.value})}/>
        </div>
    </div>
    <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Bank Address</label>
            <input type="text" placeholder="Bank Address" defaultValue={addDistributor.bankAddress}
            onChange={e =>setUpdateDistributor({...updateDistributor, bankAddress:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Country</label>
            <input type="text" placeholder="Country" defaultValue={addDistributor.bankcountry}
            onChange={e =>setUpdateDistributor({...updateDistributor, bankcountry:e.target.value})}/>
        </div>
    </div>
        <h5 className="miniHeader">Sponsor Details</h5>
        <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Sponsor ID(UL2)</label>
            <input type="text" placeholder="Sponsor ID (UL2)" defaultValue={addDistributor.sponsorId_UL2}
            onChange={e =>setUpdateDistributor({...updateDistributor, sponsorId_UL2:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Sponsor's Name</label>
            <input type="text" placeholder="Sponsor's Name (UL2)" defaultValue={addDistributor.sponsorName_UL2}
            onChange={e =>setUpdateDistributor({...updateDistributor, sponsorName_UL2:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Contact Number</label>
            <input type="text" placeholder="Contact Number (UL2)" defaultValue={addDistributor.contactNo_UL2}
            onChange={e =>setUpdateDistributor({...updateDistributor, contactNo_UL2:e.target.value})}/>
        </div>
    </div>
        <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
            <label>Upline Sponsor ID(UL1)</label>
            <input type="text" placeholder="Upline Sponsor ID (UL1)" defaultValue={addDistributor.sponsorId_UL1}
            onChange={e =>setUpdateDistributor({...updateDistributor, sponsorId_UL1:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Sponsor's Name</label>
            <input type="text" placeholder="Sponsor's Name (UL1)" defaultValue={addDistributor.sponsorName_UL1}
            onChange={e =>setUpdateDistributor({...updateDistributor, sponsorName_UL1:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
            <label>Contact Number</label>
            <input type="text" placeholder="Contact Number (UL1)" defaultValue={addDistributor.contactNo_UL1}
            onChange={e =>setUpdateDistributor({...updateDistributor, contactNo_UL1:e.target.value})}/>
        </div>
    </div>
    
    <Stack spacing={2} direction="row" ml={1} mt={2}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Update</Button>
    </Stack>
      </div>
      
      </div>
   </>
  )
}

import React, { useState } from "react";
import Sidebar from '../../Sidebar/Sidebar'
import Appbar from '../../Appbar/Appbar'
import './Coupon.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button} from '@mui/material';
import { createCoupon} from '../../../../graphql/mutations';
import { API, graphqlOperation} from 'aws-amplify';
import {v4 as uuid} from 'uuid';
import Stack from '@mui/material/Stack';

export default function Coupon() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [addCoupon,setAddCoupon]=useState({})
  const handleClick= async()=>{
    try {
    const {couponCode,couponCopy,couponStartDate,couponEndDate,discountType,cash,createBy,amendBy}=addCoupon;
    const addCouponInput = {
      id: uuid(),
      couponCode,
      couponCopy,
      couponStartDate:startDate,
      couponEndDate:endDate,
      discountType,
      cash,
      createBy,
      amendBy
    }
  await API.graphql(graphqlOperation(createCoupon, { input: addCouponInput }));
  alert('Coupon Create Successfully');
  } catch (error) {
      console.log(error)
  }
  };

  return (
    <>
    <Appbar/>
    <Sidebar/>
    <div className="CouponContainer">
        <h2>Create Coupon</h2>
        <div className="DeliveryWidgetClient1">
        <div className="newDeliveryItem1">
        <label>Coupon Code</label>
        <input type="text" placeholder="" value={addCoupon.couponCode} onChange={e =>setAddCoupon({...addCoupon, couponCode:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
        <label>Coupon Copy's</label>
        <input type="text" placeholder="" value={addCoupon.couponCopy} onChange={e =>setAddCoupon({...addCoupon, couponCopy:e.target.value})}/>
        </div>
        <div className="newDeliveryItem1">
       <label>Coupon Start Date</label>
       <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect dateFormat="Pp" className="datepicker"/>
        </div>
       <div className="newDeliveryItem1">
       <label>Coupon End Date</label>
       <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} showTimeSelect dateFormat="Pp" className="datepicker"/>
        </div>
        <div className="newDeliveryItem1">
        <label>Discount Type</label>
        <select id="example" class="autocomplete" value={addCoupon.discountType} onChange={e =>setAddCoupon({...addCoupon, discountType:e.target.value})}>
            <option value="None">None</option>
            <option value="Cash">Cash</option>
            <option value="3">3%</option>
            <option value="5">5%</option>
            <option value="10">10%</option>
            <option value="15">15%</option>
            <option value="20">20%</option>
        </select>
        </div>
        <div className="newDeliveryItem1">
        <label>Cash Amount</label>
        <input type="text" placeholder="" value={addCoupon.cash} onChange={e =>setAddCoupon({...addCoupon, cash:e.target.value})}/>
        </div>
      </div>
      <br/><br/>
      <div className="newDeliveryItem1">
        <label>Created By</label>
        <input type="text" placeholder="" value='Admin' onChange={e =>setAddCoupon({...addCoupon, createBy:e.target.value})}/>
        </div>
      <Stack spacing={2} direction="row" mb={3} mt={3}>
    <Button variant="contained" onClick={handleClick} style={{ background: 'rgb(64,64,64)' }}>Create</Button>
    </Stack>
    </div>
</>
  )
}

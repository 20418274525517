import Appbar from '../Appbar/Appbar'
import Content from './Content'
import Footer from './Footer'
import ProductValue from './ProductValues'
import ProductCategories from './ProductCategories'
import ProductHowItWorks from "./ProductHowItsWorks";
import { createAddDistributor} from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import React,{useState, useEffect } from "react";
import {getAddDistributor} from '../../graphql/queries';
export default function Home(props) {
  const Email = props.items[0]
  const phone = props.items[2]
  const ID = props.items[3]
  console.log(Email,phone,ID)
  const [addDistributor,setAddDistributor]=useState({})
  const [addDistributor1,setAddDistributor1]=useState({})
  useEffect(() => {
    handleClick();
    fetchDistributor();
  }, []);
  const handleClick= async()=>{
    try {
    const {phoneNo,email}=addDistributor;
    const addDistributorInput = {
      id: ID,
      phoneNo:phone,
      email:Email
    }
  await API.graphql(graphqlOperation(createAddDistributor, { input: addDistributorInput }));
} catch (error) {
      console.log(error)
}
  };
  const fetchDistributor = async () => {
    try {
    const DistributorData = await API.graphql(graphqlOperation(getAddDistributor, { id: ID }));
    const DistribitorList = DistributorData.data.getAddDistributor;
    console.log(DistribitorList);
    setAddDistributor1(DistribitorList);
    } catch (error) {
        console.log(error)
    }
  };
    return (
      <div>
        <div className="sticky">
       <Appbar item={ID} name={addDistributor1.firstname}/>
       </div>
        <Content/>
        <ProductValue/>
        <ProductCategories/>
        <ProductHowItWorks/>
       <Footer/>
      </div>
    );
  }